<template>
    <div class="patent-chars mt-5">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div v-else-if="error" class="my-10">
            <AonAlertBox class="mx-auto" type="error" :title="error" />
        </div>
        <template v-if="!loading">
            <AonContainer>
                <AonRow>
                    <AonCol class="aon-col-4 py-0 pl-0">
                        <AonCard title="" style="position: relative; min-height: 300px">
                            <GlobeChart :chart-data="worldMapData" />
                            <div class="worldview-tooltip">
                                <VDropdown
                                    placement="top"
                                    :distance="5"
                                    :triggers="['hover']"
                                    :popper-triggers="['hover']"
                                >
                                    <div class="tooltip-container">
                                        Other
                                        <font-awesome-icon
                                            icon="fas fa-circle-info"
                                            class="d-inline other-icon pl-1"
                                        />
                                    </div>
                                    <template #popper>
                                        <PopperPatentJurisdictions
                                            :jurisdictions="nonStandardRegions"
                                        ></PopperPatentJurisdictions>
                                    </template>
                                </VDropdown>
                            </div>
                        </AonCard>
                    </AonCol>
                    <AonCol class="aon-col-8 py-0">
                        <MoatTable
                            style="width: 100%; height: 400px"
                            :class="themeClass"
                            :column-defs="colDefs"
                            :suppress-auto-size="true"
                            :row-data="tableData.labels"
                            :sorting-order="['desc', 'asc', null]"
                            @grid-ready="gridInit"
                            :statusBar="statusBar"
                        />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, inject, nextTick } from 'vue'
import { useEntityStore, usePatentViewerStore } from '@/stores'
import GlobeChart from './GlobeChart.vue'
import PopperPatentJurisdictions from '@/components/poppers/PopperPatentJurisdictions.vue'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

const showPatentList = useFlag('ipAlpha.showPatentList')

const entityStore = useEntityStore()
const patentViewerStore = usePatentViewerStore()

const logger = inject('logger')
const eventBus = inject('eventBus')
const filters = inject('filters')
const { t } = useI18n()

const emit = defineEmits(['patent-characteristics'])
const props = defineProps({
    currentTab: {
        type: Number,
        default: 0,
    },
})

const nonStandardRegionCodes = ['EP', 'EA', 'WO', 'OA', 'AP']
const loading = ref(true)
const tableLoading = ref(false)
const tableData = ref([])
const error = ref(false)
const clickedCountry = ref(null)
const gridApi = ref(null)
const gridParams = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'region',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return t(`countries.${p.data.region}.name`)
            }
        },
        sortingOrder: ['asc', 'desc', null],
    },
    {
        field: 'appCount',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.appCount.toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: showPatentList.value,
            cellClick: async (params) => {
                onShowPatentList(params)
            },
        },
    },
    {
        field: 'grantCount',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.grantCount.toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: showPatentList.value,
            cellClick: async (params) => {
                onShowPatentList(params)
            },
        },
    },
    {
        field: 'total',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.total.toLocaleString()}`
            }
        },
        cellRenderer: 'MTCustomCellLinkWContext',
        cellRendererParams: {
            allowClick: showPatentList.value,
            cellClick: async (params) => {
                onShowPatentList(params)
            },
        },
    },
    {
        field: 'percentage',
        flex: 1,
    },
])
const statusBar = ref(null)

const worldMapData = computed(() => {
    let mapData = []
    if (patentBreakdown.value) {
        patentBreakdown.value
            .filter((pb) => pb.country !== 'WORLD')
            .forEach((a) => {
                mapData.push({
                    id: a.country,
                    value: a.assetCount,
                    polygonTemplate: { fill: '#2A79D2' },
                })
            })
    }
    return mapData
})
const jurisdictionData = computed(() => {
    if (!patentBreakdown.value) {
        return null
    }

    const worldBreakdown = patentBreakdown.value.find((pb) => pb.country === 'WORLD')

    return patentBreakdown.value
        .filter((pb) => pb.country !== 'WORLD')
        .map((pb) => {
            return {
                ...pb,
                percentage: pb.assetCount / worldBreakdown.assetCount,
            }
        })
})
const nonStandardRegions = computed(() => {
    let jurisdictions = jurisdictionData.value
    return jurisdictions.filter((jurisdiction) => {
        return nonStandardRegionCodes.includes(jurisdiction.country)
    })
})
const allGrants = computed(() => {
    return patentBreakdown.value.find((pb) => pb.country === 'WORLD')?.grantCount
})
const allApps = computed(() => {
    return patentBreakdown.value.find((pb) => pb.country === 'WORLD')?.appCount
})
const totalAppsAndGrants = computed(() => {
    return patentBreakdown.value.find((pb) => pb.country === 'WORLD')?.assetCount
})
const entityPatentFamilyStats = computed(() => {
    if (entityStore.ipBasicsActivePatents) {
        return entityStore.entityPatentFamilyStats
    }
    // If all toggle state, then show all stats
    return {
        aonEntityPk: entityStore.entityPatentFamilyStats.aonEntityPk,
        familyCount:
            entityStore.entityPatentFamilyStats.familyCount +
            entityStore.entityPatentFamilyStats.inactiveFamilyCount,
        familyCountGrantedPatents:
            entityStore.entityPatentFamilyStats.familyCountGrantedPatents +
            entityStore.entityPatentFamilyStats.familyCountInactiveGrantedPatents,
        averageAssetCountPerFamily:
            entityStore.entityPatentFamilyStats.averageAssetCountPerFamily +
            entityStore.entityPatentFamilyStats.averageInactiveAssetCountPerFamily,
    }
})

watch(
    () => entityStore.ipBasicsActivePatents,
    () => {
        setTableData()
        if (gridParams.value) {
            gridInit(gridParams.value)
        }
    }
)

const patentBreakdown = computed(() => {
    if (entityStore.ipBasicsActivePatents) {
        return entityStore.patentBreakdown.filter(
            (patent) => patent.assetCount !== 0 || patent.grantCount !== 0 || patent.appCount !== 0
        )
    }
    // If all toggle state, then show all stats
    return entityStore.patentBreakdown
        .map((patent) => ({
            ...patent,
            assetCount: patent.assetCount + patent.inactiveAssetCount,
            grantCount: patent.grantCount + patent.inactiveGrantCount,
            appCount: patent.appCount + patent.inactiveAppCount,
            unkCount: patent.unkCount + patent.inactiveUnknownCount,
        }))
        .filter(
            (patent) => patent.assetCount !== 0 || patent.grantCount !== 0 || patent.appCount !== 0
        )
})

onMounted(async () => {
    error.value = false

    try {
        await Promise.all([
            entityStore.getEntityPatentFamilyStats(entityStore.entity.aon_entity_pk),
        ])
    } catch (err) {
        logger.error(err)
        let errorType = 'general'

        if (err?.response?.status === 504) {
            errorType = 'timeout'
        }
        error.value = t(`patents.errors.${errorType}`)
    } finally {
        loading.value = false
        tableLoading.value = true
    }
    await nextTick()
    pushContextMenuToTable()
    setTableData()
})

const gridInit = (params) => {
    gridApi.value = params.api
    gridParams.value = params
    params.api.hideOverlay()

    statusBar.value = {
        statusPanels: [
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Patent Families',
                    value: filters.toLocaleString(entityPatentFamilyStats.value.familyCount),
                },
            },
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Families with Granted Patents',
                    value: filters.toLocaleString(
                        entityPatentFamilyStats.value.familyCountGrantedPatents
                    ),
                },
            },
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Avg Patents per Family',
                    value: entityPatentFamilyStats.value.averageAssetCountPerFamily?.toFixed(2),
                    sectionBreak: true,
                },
            },
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Apps Total',
                    value: filters.toLocaleString(allApps.value),
                },
            },
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Grants Total',
                    value: filters.toLocaleString(allGrants.value),
                },
            },
            {
                statusPanel: 'MTCustomStatusBarSum',
                statusPanelParams: {
                    sumTitle: 'Comprehensive Total',
                    value: filters.toLocaleString(totalAppsAndGrants.value),
                },
            },
        ],
    }
}

const pushContextMenuToTable = (params) => {
    if (showPatentList.value) {
        colDefs.value.push({
            suppressMenu: true,
            cellRenderer: 'MTContextMenu',
            cellRendererParams: {
                actionsList: [
                    {
                        name: 'View Total Patents',
                        icon: 'fa-square-list',
                        actionName: 'patents',
                        patentsAction: (params) => {
                            onShowPatentList(params, null)
                        },
                    },
                    {
                        name: 'View Applications',
                        icon: 'fa-square-list',
                        actionName: 'applications',
                        applicationsAction: (params) => {
                            onShowPatentList(params, 'A')
                        },
                    },
                    {
                        name: 'View Grants',
                        icon: 'fa-square-list',
                        actionName: 'grants',
                        grantsAction: (params) => {
                            onShowPatentList(params, 'G')
                        },
                    },
                ],
                highlightLabel: true,
            },
            pinned: 'right',
            width: 42,
        })
    }
}

const setTableData = async () => {
    let labels = []
    let values = []
    let jurisdictions = jurisdictionData.value

    if (!jurisdictions) {
        return null
    }
    if (clickedCountry.value !== null) {
        jurisdictions = {
            [clickedCountry.value]: jurisdictions[clickedCountry.value],
        }
    }

    const sortedJurisdictions = jurisdictions.sort(sortFunc)

    sortedJurisdictions.forEach((j) => {
        labels.push({
            // checked: false,
            region: j.country,
            appCount: j.appCount ?? 0,
            grantCount: j.grantCount ?? 0,
            total: j.assetCount,
            percentage: getPercentage(j.percentage),
        })
        values.push(j.assetCount)
    })

    tableData.value = {
        labels: labels,
        values: values,
        legendId: 'legend-container',
    }

    setTimeout(() => {
        tableLoading.value = false
        eventBus.emit('world-loaded')
    }, 1000)
}

const getPercentage = (value) => {
    const percent = Math.round(value * 1000) / 10

    if (percent === 0) {
        return '<1%'
    }

    return `${percent}%`
}

const sortFunc = (a, b) => {
    return (a.assetCount ?? 0) < (b.assetCount ?? 0) ? 1 : -1
}

const onShowPatentList = async (params, passedPubType = null) => {
    const pubTypeMap = {
        appCount: 'A',
        grantCount: 'G',
    }

    const pubType = passedPubType || pubTypeMap[params.column.colId] || null

    const basicsParams = {
        country: params.data.region,
        publicationType: pubType,
    }

    if (entityStore.ipBasicsActivePatents) {
        basicsParams.active = true
    }

    if (pubType) {
        basicsParams.publicationType = pubType
    }

    patentViewerStore.entityPkList.push(entityStore.entity.aon_entity_pk)
    patentViewerStore.entityName = entityStore.entity.name
    patentViewerStore.ipBasicsEntityPK = entityStore.entity.aon_entity_pk
    patentViewerStore.ipBasicsObject = basicsParams
    await nextTick()
    patentViewerStore.showPatentViewerList = true
}
</script>

<style lang="scss" scoped>
.patent-chars {
    width: 100%;
    min-height: 400px;

    .stat-holder {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey05;
        padding-bottom: 8px;
        margin-bottom: 12px;

        p {
            color: $grey02;
        }
    }

    .a-card {
        flex-direction: column;
    }
    .worldview-tooltip {
        position: absolute;
        bottom: 20px;
        right: 20px;

        .tooltip-container {
            border-radius: 4px;
            display: flex;
            background-color: $warningBackground;
            color: $grey02;
            padding: 4px 8px;
            font-weight: 700;
            font-size: 12px;
            cursor: pointer;
            .other-icon {
                color: $grey02;
            }
        }
    }
}
:deep(.ag-cell) {
    &:not(:first-child) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
</style>
