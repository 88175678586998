import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { logger } from '@moatmetrics/vue-logger'
import { createReport, patchReport } from '@/api/reports'

import {
    requestProductAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getCompetitors,
    getProductAlignmentSummary,
} from '@/api/productAlignment.js'

import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'

export const useReportGenerationStore = defineStore('reportGeneration', () => {
    const { user } = useAuth()
    const router = useRouter()
    const generateLoading = ref(false)

    const reportName = ref(null)
    const reportTypeSelected = ref(null)
    const reportSelections = ref([])
    const trackStepsInit = ref([
        {
            stepName: 'Select Report Type',
            isCurrentStep: true,
            isCompletedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Report steps appear below',
            stepDesc: 'The steps belove will be tailored based on your selected report type.',
        },
        {
            stepName: 'Placeholder Label',
            stepDesc:
                'Quis esse nostrud magna velit deserunt consectetur ullamco adipisicing velit mollit ad pariatur dolore in.',
            redacted: true,
        },
    ])
    const baseTrackSteps = [
        {
            stepName: 'Target Company',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Select Technology Areas',
            stepDesc:
                'Choose the technology areas that matter most for your analysis. Your selections will customize the Moat2Product Report to focus on relevant IP and product alignments. Identify key areas to drive your strategic decision-making.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 3,
        },
        {
            stepName: 'Select Contenders',
            stepDesc:
                'Identify the competitors you want to compare against your Target Company. These contenders will be analyzed to highlight competitive differences and opportunities within your selected technology areas.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 4,
        },
        {
            stepName: 'Report Configuration',
            stepDesc:
                "Customize your report settings and preferences. Choose how you want your report formatted, the metrics included, and how you'd like it delivered. Tailor the report to fit your needs for maximum insight.",
            isCurrentStep: false,
            isCompletedStep: true,
            stepNum: 5,
        },
    ]
    const trackStepsCompetitiveAnalysis = ref(
        baseTrackSteps.map((step) => ({
            ...step,
            stepName: step.stepNum === 4 ? 'Select Contender Source' : step.stepName,
        }))
    )
    const trackStepsHeadToHead = ref([...baseTrackSteps])
    const trackStepsCounter = ref([...baseTrackSteps])
    const trackStepsRisk = ref([...baseTrackSteps])

    const trackStepsFinal = ref([])

    // Product Alignment
    const targetCompany = ref(null)
    const targetCompanyProductAlignmentId = ref(null)
    const productAlignmentLoading = ref(false)
    const percentComplete = ref(0)
    const nodeIds = ref([])
    const errorMsg = ref('')
    const error = ref(null)
    const contenderListLoading = ref(false)
    const groupByUltimateParent = ref(true)
    const techAreaNodes = ref([])
    const allCompetitorData = ref([])
    let interval = ref(0)

    // 'compAnalysis' reportTypeSelected specific
    const contenderSource = ref(null)
    const shouldFillContenders = ref(false)
    const shouldUpdateContenderList = ref(false)
    const shouldShowContenderSelection = ref(false)
    const lockCompanySelection = ref(false)

    const resetState = () => {
        generateLoading.value = false
        reportSelections.value = []
        trackStepsInit.value[0].isCurrentStep = true
        trackStepsInit.value[0].isCompletedStep = false
        trackStepsFinal.value = []
        contenderSource.value = null
        shouldFillContenders.value = false
        shouldShowContenderSelection.value = false
        shouldUpdateContenderList.value = false
        reportTypeSelected.value = null

        targetCompany.value = null
        targetCompanyProductAlignmentId.value = null
        productAlignmentLoading.value = false
        percentComplete.value = 0
        nodeIds.value = []
        errorMsg.value = ''
        error.value = null
        shouldUpdateContenderList.value = false
        contenderListLoading.value = false
        groupByUltimateParent.value = true
        allCompetitorData.value = []
        techAreaNodes.value = []

        clearInterval(interval)
    }

    const nextButtonDisabled = computed(() => {
        // If report type is not selected (step 1)
        if (!reportTypeSelected.value) {
            return true
        }

        // All steps are set
        if (trackStepsFinal.value.length > 0 && !isReportConfigComplete.value) {
            const currentStep = getCurrentStep()
            const correspondingReportSelection = reportSelections.value.find((selection) => {
                return selection.stepNum === currentStep.stepNum
            })

            // Special case for compAnalysis
            if (reportTypeSelected.value === 'compAnalysis' && contenderSource.value) {
                if (contenderSource.value === 'manual' && shouldShowContenderSelection.value) {
                    if (
                        !correspondingReportSelection ||
                        !correspondingReportSelection.value ||
                        correspondingReportSelection.value.length === 0
                    ) {
                        return true
                    }
                }
                return false
            }

            // Common validation for all report types
            if (
                !correspondingReportSelection ||
                !correspondingReportSelection.value ||
                correspondingReportSelection.value.length === 0
            ) {
                return true
            }
        }

        return false
    })

    const isReportConfigComplete = computed(() => {
        let isComplete = true

        trackStepsFinal.value.forEach((step) => {
            if (!step.isCompletedStep) {
                isComplete = false
            }
        })

        if (trackStepsFinal.value[trackStepsFinal.value.length - 1]?.isCurrentStep !== true) {
            isComplete = false
        }

        return isComplete
    })

    const getSelectedReportTypeSteps = () => {
        if (reportSelections.value[0]?.value === 'compAnalysis') {
            return trackStepsCompetitiveAnalysis.value
        } else if (reportSelections.value[0]?.value === 'headToHead') {
            return trackStepsHeadToHead.value
        } else if (reportSelections.value[0]?.value === 'counter') {
            return trackStepsCounter.value
        } else if (reportSelections.value[0]?.value === 'risk') {
            return trackStepsRisk.value
        } else {
            return trackStepsCompetitiveAnalysis.value
        }
    }

    const getReportSelectionByStep = (stepNum) => {
        return reportSelections.value.find((s) => s.stepNum === stepNum)
    }

    const setReportCreationType = (type, label) => {
        reportTypeSelected.value = type

        const existingFirstStep = reportSelections.value.find((s) => s.stepNum === 1)
        if (existingFirstStep) {
            existingFirstStep.value = type
            existingFirstStep.selectionLabel = label
        } else {
            reportSelections.value.unshift({
                value: type,
                selectionLabel: label,
                stepNum: 1,
            })
        }
    }

    const setReportCompanySelection = (value) => {
        const currentStep = getCurrentStep()

        if (!value) {
            // Remove the selection for current step if it exists
            const index = reportSelections.value.findIndex((s) => s.stepNum === currentStep.stepNum)
            if (index > -1) {
                reportSelections.value.splice(index, 1)
            }
            return
        }

        const newSelection = {
            value: value.aon_entity_pk,
            selectionLabel: value.name || value.aonEntityName,
            stepNum: currentStep.stepNum,
            allowEdit: true,
        }

        // Find the correct index to insert based on stepNum
        const insertIndex = reportSelections.value.findIndex((s) => s.stepNum > currentStep.stepNum)
        const existingIndex = reportSelections.value.findIndex(
            (s) => s.stepNum === currentStep.stepNum
        )

        if (existingIndex > -1) {
            // Update existing selection
            reportSelections.value[existingIndex] = newSelection
        } else if (insertIndex > -1) {
            // Insert at correct position
            reportSelections.value.splice(insertIndex, 0, newSelection)
        } else {
            // Add to end if no higher stepNum exists
            reportSelections.value.push(newSelection)
        }
    }

    /////////////////////////////////////
    // Step navigation and Creation
    /////////////////////////////////////
    const getCurrentStep = () => {
        return trackStepsFinal.value.find((s) => s.isCurrentStep)
    }

    const setCurrentStep = (stepNum) => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
        trackStepsFinal.value[stepNum - 1].isCurrentStep = true
        trackStepsFinal.value[stepNum - 1].isCompletedStep = false

        if (stepNum === 4) {
            shouldShowContenderSelection.value = false
        }
    }

    const setCompletedStep = async () => {
        const currentStep = getCurrentStep()
        if (!currentStep) {
            handleInitialStep()
            return
        }

        let currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
        switch (reportTypeSelected.value) {
            case 'compAnalysis':
            case 'headToHead':
            case 'counter':
            case 'risk':
                if (currentStepIndex === 1) {
                    const correspondingReportSelection = reportSelections.value.find(
                        (selection) => selection.stepNum === currentStep.stepNum
                    )
                    targetCompany.value = correspondingReportSelection.selectionLabel
                    checkForProductAlignment(correspondingReportSelection, currentStepIndex, true)
                } else if (currentStepIndex === 2) {
                    contenderListLoading.value = true
                    await getTargetCompetitors(
                        techAreaNodes.value.map((node) => node.nodeId),
                        currentStepIndex
                    )
                } else {
                    if (currentStepIndex === 3 && reportTypeSelected.value === 'compAnalysis') {
                        handleCompAnalysisContenderStep()
                    } else {
                        markStepComplete(currentStepIndex)
                        trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
                    }
                }
                break
        }
    }

    const handleInitialStep = () => {
        trackStepsInit.value[0].isCurrentStep = false
        trackStepsInit.value[0].isCompletedStep = true
        trackStepsFinal.value = [trackStepsInit.value[0], ...getSelectedReportTypeSteps()]

        if (reportSelections.value[1]) {
            trackStepsFinal.value[2].isCurrentStep = true
            trackStepsFinal.value[1].isCompletedStep = true
        } else {
            trackStepsFinal.value[1].isCurrentStep = true
            trackStepsFinal.value[0].isCompletedStep = true
        }
    }

    const handleCompAnalysisContenderStep = () => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        if (contenderSource.value !== null) {
            if (contenderSource.value !== 'auto' && !shouldShowContenderSelection.value) {
                toggleContenderSelection()
                return
            }
        }

        markStepComplete(currentStepIndex)
        trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
    }

    const markStepComplete = (index) => {
        trackStepsFinal.value[index].isCurrentStep = false
        trackStepsFinal.value[index].isCompletedStep = true
    }

    const gotoPreviousStep = () => {
        const currentStep = getCurrentStep()
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        if (reportTypeSelected.value === 'compAnalysis') {
            if (shouldShowContenderSelection.value && currentStepIndex === 3) {
                shouldShowContenderSelection.value = false
            } else {
                currentStep.isCurrentStep = false
                trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
            }

            if (currentStepIndex <= 3) {
                contenderSource.value = null
            }
        } else {
            currentStep.isCurrentStep = false
            trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
        }
    }
    /////////////////////////////////////
    // End Step navigation and Creation
    /////////////////////////////////////

    ///////////////////////////////////////////
    // Generate Report Submit
    ///////////////////////////////////////////
    const generateReport = async () => {
        generateLoading.value = true

        const params = buildBaseParams(
            reportTypeSelected.value,
            reportName.value,
            reportSelections.value,
            user.value
        )

        if (reportTypeSelected.value === 'compAnalysis') {
            params.report_input = buildCompAnalysisInput(
                reportSelections.value,
                shouldFillContenders.value
            )
        } else if (reportTypeSelected.value === 'headToHead') {
            params.report_input = buildHeadToHeadInput(reportSelections.value)
        } else if (reportTypeSelected.value === 'counter') {
            params.report_input = buildCounterInput(reportSelections.value)
        } else if (reportTypeSelected.value === 'risk') {
            params.report_input = buildRiskInput(reportSelections.value)
        }

        try {
            const { data } = await createReport(params)
            submitReportForGeneration(data)
        } catch (err) {
            logger.error(err)
        }
    }

    const buildBaseParams = (reportTypeSelected, reportName, reportSelections, user) => {
        return {
            report_type: getReportType(reportTypeSelected),
            report_name: reportName || reportSelections[1].selectionLabel,
            report_description: '',
            requested_application: 'ip_alpha',
            report_input: {},
            requested_user: user.id,
        }
    }

    const getReportType = (reportType) => {
        const types = {
            compAnalysis: 'product_alignment',
            counter: 'counter_assertion',
            headToHead: 'head_to_head',
            risk: 'risk_analysis',
        }
        return types[reportType] || 'product_alignment'
    }

    const buildCompAnalysisInput = (reportSelections, shouldFillContenders) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            global_contender_list: reportSelections[3].value.map(
                (contender) => contender.aonEntityPk
            ),
            global_fill_contenders: shouldFillContenders,
            tech_area_contender_list: [
                {
                    node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
                },
            ],
        }
    }

    const buildHeadToHeadInput = (reportSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            target_entity_pk: parseInt(reportSelections[1].value),
            contender_ids: [
                parseInt(reportSelections[1].value),
                ...reportSelections[3].value.map((contender) => contender.aonEntityPk),
            ],
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const buildCounterInput = (reportSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            target_entity_pk: parseInt(reportSelections[1].value),
            contender_id: reportSelections[3].value[0].aonEntityPk,
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const buildRiskInput = (reportSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            contender_ids: reportSelections[3].value.map((contender) => contender.aonEntityPk),
            node_ids: reportSelections[2].value.map((techArea) => techArea.nodeId),
        }
    }

    const submitReportForGeneration = async (reportData) => {
        const params = {
            report_action: 'submit',
        }

        try {
            await patchReport(reportData.report_pk, params)
        } catch (err) {
            logger.error(err)
        } finally {
            generateLoading.value = false
            router.push({ name: 'Reports' })
        }
    }
    ///////////////////////////////////////////
    // End Generate Report Submit
    ///////////////////////////////////////////

    const setShouldFillContenders = (bool) => {
        shouldFillContenders.value = bool
    }

    const toggleContenderSelection = () => {
        shouldShowContenderSelection.value = !shouldShowContenderSelection.value
    }

    const flagForContenderUpdate = () => {
        shouldUpdateContenderList.value = true
    }

    const onTechAreaChange = () => {
        contenderSource.value = null
        trackStepsFinal.value[3].isCompletedStep = false
        trackStepsFinal.value[3].value = []
    }

    ///////////////////////////////////////////
    // Helpers
    ///////////////////////////////////////////

    const checkForProductAlignment = async (currentStep, currentStepIndex, skipToOutput) => {
        productAlignmentLoading.value = true
        error.value = null

        try {
            const { data } = await getProductAlignmentRuns(currentStep.value)
            if (data.results.length > 0) {
                const findLatestSuccess = data.results.find(
                    (run) => run.status === 'reportComplete'
                )
                const targetData = findLatestSuccess ? findLatestSuccess : data.results[0]
                if (targetData.isStale) {
                    targetCompanyProductAlignmentId.value = null
                } else {
                    targetCompanyProductAlignmentId.value = targetData.productAlignmentId
                }
            } else {
                targetCompanyProductAlignmentId.value = null
            }
        } catch (err) {
            handleError(err.status, err.statusReason, currentStep.selectionLabel)
        }
        await getProductSegmentationData(currentStep, currentStepIndex, skipToOutput)
    }

    const getProductSegmentationData = async (currentStep, currentStepIndex, skipToOutput) => {
        productAlignmentLoading.value = true
        error.value = null

        try {
            if (!targetCompanyProductAlignmentId.value) {
                const { data } = await requestProductAlignment(currentStep.value)
                targetCompanyProductAlignmentId.value = data.productAlignmentId
            }
        } catch (err) {
            handleError(err.status, err.statusReason, currentStep.selectionLabel)
        }

        interval = setInterval(
            (function paHandler() {
                getPAStatus(currentStep, currentStepIndex, skipToOutput)
                return paHandler
            })(),
            5000
        )
    }

    const getPAStatus = async (currentStep, currentStepIndex, skipToOutput) => {
        await pollForJobData(
            targetCompanyProductAlignmentId.value,
            true,
            async (jobData) => {
                // success
                clearInterval(interval)
                techAreaNodes.value = []

                const { data } = await getProductAlignmentSummary(
                    targetCompanyProductAlignmentId.value,
                    jobData.nodes.map((item) => item.id)
                )

                techAreaNodes.value = data.nodes.filter((item) => item.displayName !== 'Other')

                const existingTechAreaSelection = reportSelections.value.find(
                    (selection) => selection.stepNum === 3
                )

                if (existingTechAreaSelection) {
                    existingTechAreaSelection.value = techAreaNodes.value
                } else {
                    reportSelections.value.push({
                        value: techAreaNodes.value,
                        selectionLabel: ' Technology Areas Selected',
                        stepNum: 3,
                        allowEdit: true,
                    })
                }

                markStepComplete(currentStepIndex)
                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
                productAlignmentLoading.value = false
            },
            (err) => {
                // failure
                handleError(err.status, err.statusReason, currentStep.selectionLabel)
                clearInterval(interval)
            },
            (progress) => {
                percentComplete.value = progress
            }
        )
    }

    const getTargetCompetitors = async (nodeIds, currentStepIndex, skipToOutput) => {
        try {
            shouldUpdateContenderList.value = true
            await getCompetitors(
                targetCompanyProductAlignmentId.value,
                nodeIds,
                null,
                groupByUltimateParent.value
            ).then((response) => {
                allCompetitorData.value = []
                allCompetitorData.value = response.data

                const handleContenderSelection = () => {
                    const newContenderSelection = {
                        value: [],
                        selectionLabel: 'Contenders Selected',
                        stepNum: 4,
                        allowEdit: true,
                    }

                    const existingSelectionIndex = reportSelections.value.findIndex(
                        (selection) => selection.stepNum === 4
                    )

                    if (existingSelectionIndex >= 0) {
                        reportSelections.value[existingSelectionIndex] = newContenderSelection
                    } else {
                        reportSelections.value.push(newContenderSelection)
                    }
                }

                handleContenderSelection()

                shouldUpdateContenderList.value = false

                markStepComplete(currentStepIndex)
                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true

                if (reportTypeSelected.value !== 'compAnalysis') {
                    shouldShowContenderSelection.value = true
                }
            })
        } catch (err) {
            logger.error(err)
        } finally {
            contenderListLoading.value = false
        }
    }

    const handleError = (err, reason, name) => {
        logger.error(err)
        percentComplete.value = 0

        switch (reason) {
            case 'noPatents':
                errorMsg.value = t('productAlignment.error.noPatents', {
                    entity: name,
                })
                break

            case 'unknownCompany':
            case 'noProducts':
                errorMsg.value = t('productAlignment.error.noProducts', {
                    entity: name,
                })
                break

            case 'systemError':
            default:
                errorMsg.value = t('productAlignment.error.msg')
        }

        productAlignmentLoading.value = false
        error.value = reason
    }

    return {
        generateLoading,
        resetState,
        trackStepsInit,
        trackStepsFinal,
        contenderSource,
        lockCompanySelection,
        reportName,
        reportTypeSelected,
        reportSelections,
        setReportCreationType,
        getSelectedReportTypeSteps,
        setCurrentStep,
        getCurrentStep,
        gotoPreviousStep,
        getReportSelectionByStep,
        nextButtonDisabled,
        setCompletedStep,
        setShouldFillContenders,
        toggleContenderSelection,
        shouldShowContenderSelection,
        flagForContenderUpdate,
        shouldUpdateContenderList,
        isReportConfigComplete,
        generateReport,
        onTechAreaChange,
        setReportCompanySelection,
        productAlignmentLoading,
        techAreaNodes,
        contenderListLoading,
        targetCompany,
        allCompetitorData,
        percentComplete,
    }
})
