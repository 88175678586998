import { defineStore } from 'pinia'
import { computed, ref, inject } from 'vue'
import { logger } from '@moatmetrics/vue-logger'
import {
    requestProductAlignment,
    getProductAlignmentRuns,
    pollForJobData,
    getCompetitors,
} from '@/api/productAlignment.js'
import { createReport, patchReport } from '@/api/reports'

import { useI18n } from 'vue-i18n'
import { useAuth } from '@/auth/authPlugin'

export const usePlaybookGenerationStore = defineStore('playbookGeneration', () => {
    const { t } = useI18n()
    const { user } = useAuth()
    const eventBus = inject('eventBus')
    const generateLoading = ref(false)

    const playbookTypeSelected = ref(null)
    const playbookSelections = ref([])
    const trackStepsInit = ref([
        {
            stepName: 'Select Playbook',
            stepDesc:
                'Playbooks are a collection of processes, strategies, or actions designed to help achieve specific goals, solve problems, or handle recurring scenarios in a systematic way.',
            isCurrentStep: true,
            isCompletedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Playbook steps appear below',
            stepDesc: 'The steps belove will be tailored based on your selected playbook type.',
        },
        {
            stepName: 'Placeholder Label',
            stepDesc:
                'Quis esse nostrud magna velit deserunt consectetur ullamco adipisicing velit mollit ad pariatur dolore in.',
            redacted: true,
        },
    ])

    const createTrackSteps = (steps) => {
        return steps.map((step, index) => ({
            stepName: step.stepName,
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: index + 2,
        }))
    }

    const trackStepsConfig = {
        base: ['Target Product Arena', 'Select Competitors', 'Playbook Output'],
        counterAssertion: ['Select Initiating Party', 'Select Responding Party', 'Playbook Output'],
        licensingOpportunities: ['Select Licensing Party', 'Playbook Output'],
    }

    const trackStepsMatchUp = ref(
        createTrackSteps(trackStepsConfig.base.map((step) => ({ stepName: step })))
    )
    const trackStepsCounterAssertion = ref(
        createTrackSteps(trackStepsConfig.counterAssertion.map((step) => ({ stepName: step })))
    )
    const trackStepsLicensingOpportunities = ref(
        createTrackSteps(
            trackStepsConfig.licensingOpportunities.map((step) => ({ stepName: step }))
        )
    )
    const trackStepsFinal = ref([])

    // Product Alignment
    const targetCompany = ref(null)
    const targetCompanyProductAlignmentId = ref(null)
    const productAlignmentLoading = ref(false)
    const percentComplete = ref(0)
    const nodeIds = ref([])
    const errorMsg = ref('')
    const error = ref(null)
    const shouldUpdateContenderList = ref(false)
    const contenderListLoading = ref(false)
    const groupByUltimateParent = ref(true)
    const allCompetitorData = ref([])
    let interval = ref(0)

    const resetState = () => {
        generateLoading.value = false
        playbookSelections.value = []
        trackStepsInit.value[0].isCurrentStep = true
        trackStepsInit.value[0].isCompletedStep = false
        trackStepsFinal.value = []
        playbookTypeSelected.value = null
        trackStepsMatchUp.value = createTrackSteps(
            trackStepsConfig.base.map((step) => ({ stepName: step }))
        )
        trackStepsCounterAssertion.value = createTrackSteps(
            trackStepsConfig.counterAssertion.map((step) => ({ stepName: step }))
        )
        trackStepsLicensingOpportunities.value = createTrackSteps(
            trackStepsConfig.licensingOpportunities.map((step) => ({ stepName: step }))
        )

        targetCompany.value = null
        targetCompanyProductAlignmentId.value = null
        productAlignmentLoading.value = false
        percentComplete.value = 0
        nodeIds.value = []
        errorMsg.value = ''
        error.value = null
        shouldUpdateContenderList.value = false
        contenderListLoading.value = false
        groupByUltimateParent.value = true
        allCompetitorData.value = []

        clearInterval(interval)
    }

    const nextButtonDisabled = computed(() => {
        // If report type is not selected (step 1)
        if (!playbookTypeSelected.value) {
            return true
        }

        // All steps are set
        if (trackStepsFinal.value.length > 0 && !isPlaybookConfigComplete.value) {
            const currentStep = getCurrentStep()
            const correspondingPlaybookSelection = playbookSelections.value.find((selection) => {
                return selection.stepNum === currentStep.stepNum
            })

            // Common validation for all playbook types
            if (
                !correspondingPlaybookSelection ||
                !correspondingPlaybookSelection.value ||
                correspondingPlaybookSelection.value.length === 0
            ) {
                return true
            }
        }

        return false
    })

    const isPlaybookConfigComplete = computed(() => {
        let isComplete = true

        trackStepsFinal.value.length === 0 ? (isComplete = false) : null

        trackStepsFinal.value.forEach((step) => {
            if (!step.isCompletedStep) {
                isComplete = false
            }
        })

        return isComplete
    })

    const getSelectedPlaybookTypeSteps = () => {
        if (playbookSelections.value[0]?.value === 'matchUp') {
            return trackStepsMatchUp.value
        } else if (playbookSelections.value[0]?.value === 'counterAssertion') {
            return trackStepsCounterAssertion.value
        } else if (playbookSelections.value[0]?.value === 'licensingOpportunities') {
            return trackStepsLicensingOpportunities.value
        } else {
            return trackStepsMatchUp.value
        }
    }

    const getPlaybookSelectionByStep = (stepNum) => {
        return playbookSelections.value.find((s) => s.stepNum === stepNum)
    }

    const setPlaybookType = (type, label) => {
        playbookTypeSelected.value = type

        const existingFirstStep = playbookSelections.value.find((s) => s.stepNum === 1)
        if (existingFirstStep) {
            existingFirstStep.value = type
            existingFirstStep.selectionLabel = label
            // existingFirstStep.allowEdit = true
        } else {
            playbookSelections.value.unshift({
                value: type,
                selectionLabel: label,
                stepNum: 1,
                // allowEdit: true,
            })
        }
    }

    const setPlaybookCompanySelection = (value) => {
        const currentStep = getCurrentStep()

        if (!value) {
            // Remove the selection for current step if it exists
            const index = playbookSelections.value.findIndex(
                (s) => s.stepNum === currentStep.stepNum
            )
            if (index > -1) {
                playbookSelections.value.splice(index, 1)
            }
            return
        }

        const newSelection = {
            value: value.aon_entity_pk,
            selectionLabel: value.name || value.aonEntityName,
            stepNum: currentStep.stepNum,
            allowEdit: true,
        }

        // Find the correct index to insert based on stepNum
        const insertIndex = playbookSelections.value.findIndex(
            (s) => s.stepNum > currentStep.stepNum
        )
        const existingIndex = playbookSelections.value.findIndex(
            (s) => s.stepNum === currentStep.stepNum
        )

        if (existingIndex > -1) {
            // Update existing selection
            playbookSelections.value[existingIndex] = newSelection
        } else if (insertIndex > -1) {
            // Insert at correct position
            playbookSelections.value.splice(insertIndex, 0, newSelection)
        } else {
            // Add to end if no higher stepNum exists
            playbookSelections.value.push(newSelection)
        }
    }

    const setPlaybookCompetitorSelection = (value) => {
        if (!value) {
            // Remove the selection for current step if it exists
            const index = playbookSelections.value.findIndex(
                (s) => s.stepNum === currentStep.stepNum
            )
            if (index > -1) {
                playbookSelections.value.splice(index, 1)
            }
            return
        }

        const currentStep = getCurrentStep()
        const findSelection = getPlaybookSelectionByStep(currentStep.stepNum)

        if (!findSelection) {
            playbookSelections.value.push({
                value: value,
                selectionLabel: 'Contenders Selected',
                stepNum: currentStep.stepNum,
                allowEdit: true,
            })
        } else {
            findSelection.value = value
        }
    }

    /////////////////////////////////////
    // Step navigation and Creation
    /////////////////////////////////////
    const getCurrentStep = () => {
        return trackStepsFinal.value.find((s) => s.isCurrentStep)
    }

    const setCurrentStep = (stepNum) => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
        trackStepsFinal.value[stepNum - 1].isCurrentStep = true
        trackStepsFinal.value[stepNum - 1].isCompletedStep = false
    }

    const setCompletedStep = () => {
        const currentStep = getCurrentStep()
        if (!currentStep) {
            handleInitialStep()
            return
        }
        let currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
        let skipCompetitors = false
        switch (playbookTypeSelected.value) {
            case 'licensingOpportunities':
                skipCompetitors = true
            case 'matchUp':
            case 'counterAssertion':
                if (currentStepIndex === 1) {
                    const correspondingPlaybookSelection = playbookSelections.value.find(
                        (selection) => selection.stepNum === currentStep.stepNum
                    )
                    targetCompany.value = correspondingPlaybookSelection.selectionLabel
                    checkForProductAlignment(
                        correspondingPlaybookSelection,
                        currentStepIndex,
                        true,
                        skipCompetitors
                    )
                } else {
                    markStepComplete(currentStepIndex)
                    trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
                }
                break
        }
    }

    const handleInitialStep = () => {
        trackStepsInit.value[0].isCurrentStep = false
        trackStepsInit.value[0].isCompletedStep = true
        trackStepsFinal.value = [trackStepsInit.value[0], ...getSelectedPlaybookTypeSteps()]

        if (playbookSelections.value[1]) {
            trackStepsFinal.value[2].isCurrentStep = true
            trackStepsFinal.value[1].isCompletedStep = true
        } else {
            trackStepsFinal.value[1].isCurrentStep = true
            trackStepsFinal.value[0].isCompletedStep = true
        }
    }

    const markStepComplete = (index) => {
        trackStepsFinal.value[index].isCurrentStep = false
        trackStepsFinal.value[index].isCompletedStep = true
    }

    const gotoPreviousStep = () => {
        const currentStep = getCurrentStep()
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        currentStep.isCurrentStep = false
        trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
    }
    /////////////////////////////////////
    // End Step navigation and Creation
    /////////////////////////////////////

    ///////////////////////////////////////////
    // Generate Report Submit
    ///////////////////////////////////////////
    const generatePlaybook = async () => {
        generateLoading.value = true

        const params = buildBaseParams(
            playbookTypeSelected.value,
            playbookSelections.value,
            user.value
        )

        if (playbookTypeSelected.value === 'matchUp') {
            params.report_input = buildMatchUpInput(playbookSelections.value)
        }

        if (playbookTypeSelected.value === 'counterAssertion') {
            params.report_input = buildCounterAssertionInput(playbookSelections.value)
        }

        if (playbookTypeSelected.value === 'licensingOpportunities') {
            params.report_input = buildLicensingOpportunitiesInput(playbookSelections.value)
        }

        try {
            const { data } = await createReport(params)
            submitPlaybookForGeneration(data)
        } catch (err) {
            logger.error(err)
        }
    }

    const buildBaseParams = (playbookTypeSelected, playbookSelections, user) => {
        return {
            report_type: getPlaybookType(playbookTypeSelected),
            report_name: `${playbookSelections[1].selectionLabel} - ${getPlaybookType(playbookTypeSelected)} - Playbook`,
            report_description: '',
            requested_application: 'ip_alpha',
            report_input: {},
            requested_user: user.id,
        }
    }

    const getPlaybookType = (reportType) => {
        const types = {
            matchUp: 'competitive_matchup',
            counterAssertion: 'counter_assertion',
            licensingOpportunities: 'licensing_opportunities',
        }
        return types[reportType] || 'competitive_matchup'
    }

    const buildMatchUpInput = (playbookSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            target_entity_pk: parseInt(playbookSelections[1].value),
            contender_ids: playbookSelections[2].value.map((contender) =>
                parseInt(contender.aonEntityPk)
            ),
            node_ids: nodeIds.value,
        }
    }

    const buildCounterAssertionInput = (playbookSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            target_entity_pk: parseInt(playbookSelections[1].value),
            contender_id: parseInt(playbookSelections[2].value[0].aonEntityPk),
            node_ids: nodeIds.value,
        }
    }

    const buildLicensingOpportunitiesInput = (playbookSelections) => {
        return {
            product_alignment_id: targetCompanyProductAlignmentId.value,
            licensor_entity_pk: parseInt(playbookSelections[1].value),
            licensee_entity_pk: parseInt(playbookSelections[2].value[0].aonEntityPk),
            node_ids: nodeIds.value,
        }
    }

    const submitPlaybookForGeneration = async (reportData) => {
        const params = {
            report_action: 'submit',
        }

        try {
            await patchReport(reportData.report_pk, params)
        } catch (err) {
            logger.error(err)
        } finally {
            generateLoading.value = false
            eventBus.emit('snacktime', {
                type: 'success',
                message:
                    'The playbook has been successfully submitted for generation. Feel free to make changes and submit another.',
                link: {
                    text: 'Reports and Playbooks:',
                    route: '/reports',
                },
                contextual: false,
            })
        }
    }
    ///////////////////////////////////////////
    // End Generate Report Submit
    ///////////////////////////////////////////

    ///////////////////////////////////////////
    // Helpers
    ///////////////////////////////////////////

    const checkForProductAlignment = async (
        currentStep,
        currentStepIndex,
        skipToOutput,
        skipCompetitors
    ) => {
        productAlignmentLoading.value = true
        error.value = null

        try {
            const { data } = await getProductAlignmentRuns(currentStep.value)
            if (data.results.length > 0) {
                const findLatestSuccess = data.results.find(
                    (run) => run.status === 'reportComplete'
                )
                const targetData = findLatestSuccess ? findLatestSuccess : data.results[0]
                if (targetData.isStale) {
                    targetCompanyProductAlignmentId.value = null
                } else {
                    targetCompanyProductAlignmentId.value = targetData.productAlignmentId
                }
            } else {
                targetCompanyProductAlignmentId.value = null
            }
        } catch (err) {
            handleError(err.status, err.statusReason, currentStep.selectionLabel)
        }
        await getProductSegmentationData(
            currentStep,
            currentStepIndex,
            skipToOutput,
            skipCompetitors
        )
    }

    const getProductSegmentationData = async (
        currentStep,
        currentStepIndex,
        skipToOutput,
        skipCompetitors
    ) => {
        productAlignmentLoading.value = true
        error.value = null

        try {
            if (!targetCompanyProductAlignmentId.value) {
                const { data } = await requestProductAlignment(currentStep.value)
                targetCompanyProductAlignmentId.value = data.productAlignmentId
            }
        } catch (err) {
            handleError(err.status, err.statusReason, currentStep.selectionLabel)
        }

        interval = setInterval(
            (function paHandler() {
                getPAStatus(currentStep, currentStepIndex, skipToOutput, skipCompetitors)
                return paHandler
            })(),
            5000
        )
    }

    const getPAStatus = async (currentStep, currentStepIndex, skipToOutput, skipCompetitors) => {
        await pollForJobData(
            targetCompanyProductAlignmentId.value,
            true,
            async (data) => {
                // success
                clearInterval(interval)
                productAlignmentLoading.value = false
                if (skipCompetitors) {
                    markStepComplete(currentStepIndex)
                    trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
                    return
                }
                contenderListLoading.value = true
                await getTargetCompetitors(
                    data.nodes.map((node) => node.id),
                    currentStepIndex,
                    skipToOutput
                )
            },
            (err) => {
                // failure
                handleError(err.status, err.statusReason, currentStep.selectionLabel)
                clearInterval(interval)
            },
            (progress) => {
                percentComplete.value = progress
            }
        )
    }

    const getTargetCompetitors = async (nodeIds, currentStepIndex, skipToOutput) => {
        try {
            shouldUpdateContenderList.value = true
            await getCompetitors(
                targetCompanyProductAlignmentId.value,
                nodeIds,
                null,
                groupByUltimateParent.value
            ).then((response) => {
                allCompetitorData.value = response.data

                if (playbookTypeSelected.value === 'matchUp') {
                    if (playbookSelections.value[2]) {
                        playbookSelections.value[2].value = response.data
                            .filter((item) => item.aonEntityName !== targetCompany.value)
                            .slice(0, 5)
                    } else {
                        playbookSelections.value.push({
                            value: response.data
                                .filter((item) => item.aonEntityName !== targetCompany.value)
                                .slice(0, 5),
                            selectionLabel: 'Contenders Selected',
                            stepNum: 3,
                            allowEdit: true,
                        })
                    }
                } else {
                    playbookSelections.value.push({
                        value: [],
                        selectionLabel: 'Contenders Selected',
                        stepNum: 3,
                        allowEdit: true,
                    })
                }

                shouldUpdateContenderList.value = false

                markStepComplete(currentStepIndex)
                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
            })
        } catch (err) {
            logger.error(err)
        } finally {
            contenderListLoading.value = false
        }
    }

    const handleError = (err, reason, name) => {
        logger.error(err)
        percentComplete.value = 0

        switch (reason) {
            case 'noPatents':
                errorMsg.value = t('productAlignment.error.noPatents', {
                    entity: name,
                })
                break

            case 'unknownCompany':
            case 'noProducts':
                errorMsg.value = t('productAlignment.error.noProducts', {
                    entity: name,
                })
                break

            case 'systemError':
            default:
                errorMsg.value = t('productAlignment.error.msg')
        }

        productAlignmentLoading.value = false
        error.value = reason
    }

    return {
        generateLoading,
        resetState,
        trackStepsInit,
        trackStepsFinal,
        playbookTypeSelected,
        playbookSelections,
        setPlaybookType,
        getSelectedPlaybookTypeSteps,
        setCurrentStep,
        getCurrentStep,
        gotoPreviousStep,
        getPlaybookSelectionByStep,
        nextButtonDisabled,
        setCompletedStep,
        isPlaybookConfigComplete,
        generatePlaybook,
        setPlaybookCompanySelection,
        setPlaybookCompetitorSelection,
        targetCompanyProductAlignmentId,
        productAlignmentLoading,
        percentComplete,
        errorMsg,
        error,
        targetCompany,
        nodeIds,
        shouldUpdateContenderList,
        groupByUltimateParent,
        allCompetitorData,
        contenderListLoading,
    }
})
