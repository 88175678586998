<template>
    <div class="search-companies-table my-5">
        <TableAips
            ref="searchCompaniesTable"
            disable-filters
            table-style="fixed-scrolling"
            sticky-header
            :table-height="props.type === 'arena' ? '380px' : '80vh'"
            table-density="compact"
            toggle-columns
            :columns="columns"
            :data="data"
            :default-filter="defaultFilter"
            :loading-data="loading"
            :page-size="20"
            :show-bulk-actions="
                selectedCompanyIds.length > 0 && props.type !== 'arena' && props.type !== 'company'
            "
            :table-bulk-actions="tableBulkActions"
            :table-collapse="false"
            :table-name="tableTitle"
            :use-page-data="false"
            @loadData="loadTableData"
            @bulk-action-selected="selectAction"
            @table-row-checked="selectRow"
        >
            <template #bulkActionBarPrepend>
                <div class="holder mr-10 d-flex">
                    <p class="white--text link mr-2" @click="clearSelectedData">Clear</p>
                    <p class="white--text mr-2">•</p>
                    <p class="selections bold white--text">
                        {{ filters.toLocaleString(selectedCompanies.length) }}
                        {{ t('actions.selected') }}
                    </p>
                </div>
            </template>
            <template #tableHeaderActionsPrepend>
                <!-- // TODO replace with new searchComponent when ready -->
                <div
                    class="search-wrapper ml-4"
                    id="context-target-add-company"
                    style="position: relative"
                >
                    <AonInput
                        v-model="searchTerm"
                        search-input
                        placeholder="Search Companies"
                        :disabled="loading"
                        @keyup.enter="search()"
                    />
                </div>
            </template>
            <template #tableMessage>
                <div class="height-holder w-full d-flex align-items-center">
                    <AonSkeleton
                        class="w-full d-flex align-items-center"
                        :loading="loading && totalDataLength === 0"
                        :data="totalDataLength"
                        light
                    >
                        <div
                            class="info d-flex align-items-center flex-wrap nowrap"
                            style="width: fit-content"
                        >
                            {{
                                t('actions.showingCountOf', {
                                    count: filters.toLocaleString(data.length),
                                    total: filters.toLocaleString(totalDataLength),
                                    units: 'items',
                                })
                            }}
                        </div>
                    </AonSkeleton>
                </div>
            </template>
            <template #name="{ data }">
                <CompanySearchResult
                    :company="data"
                    :search-term="searchTerm"
                    type="arena"
                    @set-hierarchy-company="setCompanyHierarchyEntity(data)"
                    @show-company-summary="showCompanySummary(data.aon_entity_pk)"
                />
            </template>
            <template #ultimate_aon_entity_name="{ data }">
                <div class="wrap d-flex align-items-center">
                    <p
                        v-if="!isUltimateParent(data)"
                        class="link overflow-ellipsis"
                        v-tooltip="{ content: data.ultimate_aon_entity_name }"
                        @click="showCompanySummary(data.ultimate_aon_entity_pk)"
                    >
                        {{ data.ultimate_aon_entity_name }}
                    </p>
                    <p v-else>--</p>
                </div>
            </template>
            <template #homepage_url="{ data }">
                <div class="wrap d-flex align-items-center">
                    <a
                        v-if="!!data.homepage_url"
                        target="_blank"
                        v-tooltip="{ content: data.homepage_url }"
                        :href="data.homepage_url"
                    >
                        <font-awesome-icon icon="fas fa-external-link" class="ml-2" />
                        {{ data.homepage_url }}
                    </a>
                    <p v-else v-tooltip="{ content: '--' }">--</p>
                </div>
            </template>
        </TableAips>
        <CompanyHierarchy
            v-if="showCompanyHierarchy"
            :parent-entity-id="companyHierarchyEntityParentId"
            :selected-entity-id="companyHierarchyEntityId"
            @cancel="showCompanyHierarchy = false"
        />
    </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDatasetStore, useEntityStore, useArenaStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { getEntitySearchQuery, getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'

import CompanyHierarchy from '@/components/company/CompanyHierarchy'
import CompanySearchResult from '@/components/company/CompanySearchResult'

const emit = defineEmits(['add-node-to-arena', 'reset-arena-selections', 'comp-selected'])

const props = defineProps({
    tableTitle: {
        type: String,
        default: 'Companies',
    },
    type: {
        type: String,
        default: 'standard',
    },
})

const eventBus = inject('eventBus')
const filters = inject('filters')
const logger = inject('logger')

const datasetStore = useDatasetStore()
const entityStore = useEntityStore()
const arenaStore = useArenaStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

let defaultFilter = []

const companyHierarchyEntityId = ref(null)
const companyHierarchyEntityParentId = ref(null)
const data = ref([])
const loading = ref(true)
const searchCompaniesTable = ref(null)
const searchTerm = ref(null)
const selectedCompanies = ref([])
const showCompanyHierarchy = ref(false)
const totalDataLength = ref(0)

const columns = computed(() => {
    return [
        {
            checkboxValue: 'checked',
            showColumn: true,
            hasCheckbox: true,
            headerCheckbox: false,
            hideFromColToggle: true,
            hideFromFilters: true,
            width: '70px',
            disableSort: true,
        },
        {
            value: 'name',
            locale: t('screeners.company.headers.name'),
            showColumn: true,
            filterType: 'string',
            useSlot: true,
            disableTooltip: true,
            stickyColumn: true,
            width: '400px',
            disableSort: true,
        },
        {
            value: 'hq_country_name',
            locale: t('screeners.company.headers.hq_country_name'),
            showColumn: true,
            filterType: 'string',
            width: '125px',
            disableSort: true,
        },
        {
            value: 'ultimate_aon_entity_name',
            locale: t('companies.ultimateParent'),
            showColumn: true,
            useSlot: true,
            disableTooltip: true,
            filterType: 'string',
            width: '250px',
            disableSort: true,
        },
        {
            value: 'employee_count',
            locale: t('screeners.company.headers.employee_count'),
            showColumn: true,
            filterType: 'number',
            width: '155px',
            disableSort: true,
        },
        {
            value: 'total_revenue',
            locale: 'Annual Revenue',
            showColumn: true,
            filterType: 'number',
            width: '155px',
            disableSort: true,
        },
        {
            value: 'patent_count_us',
            locale: 'Active Assets',
            showColumn: true,
            filterType: 'number',
            width: '145px',
            disableSort: true,
        },
        {
            value: 'ownership_status',
            locale: 'Ownership Status',
            showColumn: true,
            filterType: 'string',
            width: '165px',
            disableSort: true,
        },
        {
            value: 'homepage_url',
            locale: 'URL',
            showColumn: true,
            useSlot: true,
            disableTooltip: true,
            filterType: 'string',
            width: '300px',
            disableSort: true,
        },
    ]
})
const searchtFilterValues = computed(() => {
    // TODO DRY up this duplicated searchTerm logic... check for null errors
    if (!searchTerm.value || searchTerm.value.trim() === '') {
        return []
    }
    // TODO need to add OR filters for ticker, url
    return [
        {
            filterColumn: 'name',
            filterLabel: 'Company',
            filterType: 'text contains',
            filterValue: searchTerm.value.trim(),
        },
    ]
})
const tableTitle = computed(() => {
    return props.tableTitle
})
const searchTermTrimmed = computed(() => {
    return searchTerm.value ? searchTerm.value.trim() : searchTerm.value
})
const selectedCompanyIds = computed(() => {
    return selectedCompanies.value.map((sm) => sm.id)
})
const tableBulkActions = computed(() => {
    const selectedCount = selectedCompanyIds.value.length
    const isDisabled = !selectedCount || selectedCount > 25
    const tableActions = [
        {
            itemText: t('actions.addToWatchlist.label'),
            disabledRule: !isDisabled,
            disabledMsg: t('actions.addToWatchlist.disabled'),
            faIconStyle: 'fas',
            faIcon: 'list-dropdown',
        },
    ]

    return tableActions
})

onBeforeMount(() => {
    entityStore.summaryEntity = null

    if (route.query.filter) {
        const json = atob(route.query.filter)

        const screener = JSON.parse(json)
        const nameFilter = screener.filters.find((sf) => sf.filterColumn === 'name')

        searchTerm.value = nameFilter ? nameFilter.filterValue : null
    } else {
        defaultFilter = searchtFilterValues.value
    }
})

const addSelectedToWatchlist = () => {
    datasetStore.setIdsToAdd({
        id_type: 'Entity',
        id_values: selectedCompanyIds.value,
    })
}
const clearSelectedData = () => {
    selectedCompanies.value = []
    setCheckboxSelections()
}
const isUltimateParent = (company) => {
    return company.aon_entity_pk === company.ultimate_aon_entity_pk
}
const getTableData = async (params = {}) => {
    try {
        loading.value = true
        if (searchTermTrimmed.value) {
            params['FV'] = [searchTermTrimmed.value]
        }
        const osParams = getEntitySearchQuery(params)
        const response = await osApi.searchEntities(osParams)

        totalDataLength.value = response.data.hits.total.value

        let scrubbedData = response.data.hits.hits.map((src) => {
            const item = src._source

            return {
                ...item,
                checked: selectedCompanyIds.value.includes(item.aon_entity_pk),
                employee_count: filters.toLocaleString(item.employee_count),
                patent_count_us: filters.toLocaleString(item.patent_count_us),
                total_revenue: filters.abbreviate(item.total_revenue),
            }
        })

        if (osParams.from === 0) {
            data.value = [...scrubbedData]
        } else {
            data.value.push(...scrubbedData)
        }
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('error.genericError'),
        })
    }

    loading.value = false
}
const loadTableData = async ({ params }) => {
    await getTableData(params)
}
const search = async () => {
    selectedCompanies.value = []
    emit('reset-arena-selections')
    if (!searchTerm.value || searchTerm.value.trim() === '') {
        searchCompaniesTable.value.removeFilter(0)
    }

    searchCompaniesTable.value.applyTableFilters(searchtFilterValues.value)
}
const selectAction = async (action) => {
    const [watchlist] = [t('actions.addToWatchlist.label')]
    switch (action.itemText) {
        case watchlist:
            addSelectedToWatchlist()
            break
    }
}
const selectRow = (row) => {
    emit('add-node-to-arena', { data: row, source: null })

    if (props.type === 'company') {
        emit('comp-selected', row)
    }

    const companyIndex = selectedCompanies.value.findIndex((sm) => sm.id === row.aon_entity_pk)

    if (!row.checked && companyIndex < 0) {
        selectedCompanies.value.push({ name: row.name, id: row.aon_entity_pk })
        setCheckboxSelections()
        return
    }

    if (row.checked && companyIndex >= 0) {
        selectedCompanies.value.splice(companyIndex, 1)
        setCheckboxSelections()
        return
    }
}
const setCheckboxSelections = () => {
    const tableData = [...data.value]

    data.value = tableData.map((val) => {
        val.checked = selectedCompanies.value.map((e) => e.id).includes(val.aon_entity_pk)
            ? true
            : false
        return val
    })
}
const setCompanyHierarchyEntity = (rowEntity) => {
    companyHierarchyEntityId.value = rowEntity.aon_entity_pk
    companyHierarchyEntityParentId.value = rowEntity.ultimate_aon_entity_pk
        ? parseInt(rowEntity.ultimate_aon_entity_pk)
        : parseInt(rowEntity.aon_entity_pk)
    showCompanyHierarchy.value = true
}
const showCompanySummary = async (entityId) => {
    try {
        const dslQuery = getOpenSearchQuery({
            FC: ['aon_entity_pk'],
            FT: ['eq'],
            FV: [entityId],
        })
        const { data } = await osApi.searchEntities(dslQuery)

        let company = data.hits.hits.map((h) => h._source)[0]
        entityStore.setEntity(company)
        entityStore.setSummaryEntity(company)
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('market.errors.quickSummary'),
        })
    }
}
</script>

<style lang="scss" scoped>
.search-companies-table {
    width: 100%;
    overflow: hidden;

    .search-wrapper {
        width: 300px;
    }
}

:deep(.table-aips-latest) {
    .action-holder {
        .fa-filter {
            display: none !important;
        }
    }
    .table-filters-container {
        height: 0px !important;
    }
    .table-actions {
        width: 3em !important;
    }
    .aips-table-wrap {
        padding-bottom: 100px;
        // max-height: calc(100% - 100px) !important;
    }
    .no-value {
        display: block !important;
    }
    .table-filters {
        .full-row {
            &:first-of-type {
                display: none;
            }
        }
    }
    .aips-table {
        th {
            box-shadow: none !important;
            // TODO fix in the global_table as this causes some UI 'weirdness' as described in ATEAM-1947
            &:hover {
                background-color: inherit !important;
            }
        }
    }
    .table-aips-headers {
        th {
            &:first-of-type {
                cursor: default !important;

                &:hover {
                    background: white !important;
                }
            }
            &:hover {
                background: white !important;
            }
        }
    }
}
</style>
