<template>
    <AonModal class="patent-list" message="" col-width="10" @close="closePatentList">
        <div class="modal-content d-flex flex-column">
            <PatentDetails v-show="patentViewerStore.patentDetailsOpen" />
            <div class="patent-list-header knights-cloak d-flex align-items-center">
                <div class="source-display py-4 px-4">
                    <p
                        v-if="patentViewerStore.listViewType === 'lit'"
                        class="large bold white--text"
                    >
                        Litigation for:
                    </p>
                    <div class="basics-wrap d-flex align-items-center">
                        <p
                            v-if="patentViewerStore.ipBasicsObject?.country"
                            class="large bold white--text mr-1"
                        >
                            {{ patentViewerStore.ipBasicsObject?.country }} |
                        </p>
                        <p v-if="patentViewerStore.ipBasicsObject" class="large bold white--text">
                            {{
                                patentViewerStore.ipBasicsObject?.publicationType === 'A'
                                    ? 'Applications'
                                    : patentViewerStore.ipBasicsObject?.publicationType === 'G'
                                      ? 'Grants'
                                      : 'Total'
                            }}
                            {{
                                patentViewerStore.ipBasicsObject?.claimBreadth
                                    ? ' | ' + patentViewerStore.ipBasicsObject?.claimBreadth
                                    : ''
                            }}
                            {{
                                patentViewerStore.ipBasicsObject?.validity
                                    ? ' - ' + patentViewerStore.ipBasicsObject?.validity
                                    : ''
                            }}
                        </p>
                        <!-- <p v-else class="large bold white--text">Total</p> -->
                    </div>
                    <h4 class="large bold white--text">
                        {{ patentViewerStore.entityName
                        }}{{
                            patentViewerStore.technologyName
                                ? ` - ${patentViewerStore.technologyName}`
                                : ''
                        }}
                    </h4>
                </div>
            </div>
            <div class="table-holder d-flex flex-column flex-grow-1 h-full pa-4">
                <div class="totals-holder mb-4 d-flex align-items-center justify-content-between">
                    <div class="patents d-flex align-items-center">
                        <p class="bold grey01--text">
                            Total Patent Results: {{ totalPatents.toLocaleString() }}
                        </p>
                        <div class="divide mx-4"></div>
                        <p class="bold grey01--text">
                            Filtered Patents: {{ filteredPatents.toLocaleString() }}
                        </p>
                    </div>
                    <AonButton
                        v-if="showPatentDownload"
                        label="Export Current Patent List"
                        :loading="exportLoading"
                        :text-with-icon="true"
                        type="prominent"
                        :disabled="noRows || loading"
                        :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                        @clicked="exportPatents()"
                    />
                </div>
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :side-bar="sideBar"
                    :autoSizeStrategy="sizingStrategy"
                    row-model-type="serverSide"
                    :cache-block-size="100"
                    :max-blocks-in-cache="50"
                    :get-row-id="getRowId"
                    :row-selection="rowSelection"
                    :noRowsOverlayComponent="MTCustomNoRowsOverlay"
                    :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
                    @grid-ready="gridInit"
                    @cell-clicked="onRowSelected"
                    @cell-key-down="onCellKeyDown"
                />
            </div>
        </div>
    </AonModal>
</template>

<script setup>
import { ref, inject, onBeforeUnmount, onMounted } from 'vue'
import { usePatentViewerStore, useEntityStore, useMoat2ProductStore } from '@/stores'
import { getPatentList, getLitPatentList, getPatentListIPBasics } from '@/api/patentViewer.js'
import { createReport, patchReport } from '@/api/reports'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'
import MTCustomNoRowsOverlay from '@/components/moatTable/MTCustomNoRowsOverlay.vue'
import PatentDetails from '@/components/patentViewer/PatentDetails.vue'

import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash-es'
import { useFlag } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'

const { user } = useAuth()
const logger = inject('logger')
const filters = inject('filters')
const eventBus = inject('eventBus')
const { t } = useI18n()

const showPatentDownload = useFlag('ipAlpha.showPatentListDownload')

const patentViewerStore = usePatentViewerStore()
const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const emit = defineEmits(['close-patent-list'])

const loading = ref(true)
const exportLoading = ref(false)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2801,
})
const rowSelection = ref({
    mode: 'singleRow',
    checkboxes: false,
    enableClickSelection: true,
    suppressRowDeselection: true,
})
const noRowsOverlayComponentParams = ref({
    message: `There doesn\'t seem to be any patents for ${patentViewerStore.entityName} - ${patentViewerStore.technologyName}`,
})
const tableParams = ref({})
const noRows = ref(false)
const tablePageSize = ref(100)
const tablePageNum = ref(0)
const totalPatents = ref(0)
const filteredPatents = ref(0)
const colDefs = ref([
    {
        field: 'patentId',
        width: 140,
        cellClass: 'link',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'title',
        flex: 2,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'entities',
        headerName: 'Assignee',
        flex: 2,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'ultimateEntities',
        headerName: 'Ultimate Assignee',
        flex: 2,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'legalStatus',
        width: 140,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['active', 'inactive'],
            valueFormatter: (p) => {
                if (p.value) {
                    return p.value === 'active' ? 'Active' : 'Inactive'
                }
            },
        },
    },
    {
        headerName: 'Filing Date',
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.filingDate)

                if (date === '1/1/1') {
                    date = '--'
                }

                return `${date}`
            }
        },
        field: 'filingDate',
        sort: 'desc',
        comparator: dateComparator,
        width: 140,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
        },
    },
    {
        headerName: 'Publication Date',
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.publicationDate)

                if (date === '1/1/1') {
                    date = '--'
                }

                return `${date}`
            }
        },
        field: 'publicationDate',
        comparator: dateComparator,
        width: 140,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
        },
    },
    {
        headerName: 'Priority Date',
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.priorityDate)

                if (date === '1/1/1') {
                    date = '--'
                }
                return `${date}`
            }
        },
        field: 'priorityDate',
        comparator: dateComparator,
        width: 140,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
        },
    },
    {
        headerName: 'Est. Expiration Date',
        valueGetter: (p) => {
            if (p.data) {
                let date = filters.toUTCString(p.data.estimatedExpirationDate)

                if (date === '1/1/1') {
                    date = '--'
                }

                return `${date}`
            }
        },
        field: 'estimatedExpirationDate',
        comparator: dateComparator,
        width: 150,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'applicationNumber',
        width: 150,
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'claimBreadth',
        width: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Very Broad', 'Broad', 'Average', 'Narrow', 'Not Scored'],
        },
    },
    {
        field: 'validity',
        width: 110,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Low', 'Medium', 'High', 'Not Ranked'],
        },
    },
    {
        field: 'litigationCount',
        width: 130,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.litigationCount.toLocaleString()
            }
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'jurisdiction',
        width: 130,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
])
const selectedPatentIndex = ref(0)
const sideBar = ref(null)
const previousSortModel = ref([])

onMounted(() => {
    eventBus.on('next-patent', () => {
        const nextRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (nextRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value + 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [nextRow], newValue: true })
            patentViewerStore.targetPatentId = nextRow.data.publicationPk
            selectedPatentIndex.value++
        }
    })
    eventBus.on('previous-patent', () => {
        const previousRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (previousRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value - 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [previousRow], newValue: true })
            patentViewerStore.targetPatentId = previousRow.data.publicationPk
            selectedPatentIndex.value--
        }
    })

    eventBus.on('close-patent', () => {
        patentViewerStore.targetPatentId = null
    })
})

onBeforeUnmount(() => {
    patentViewerStore.resetState()
    eventBus.off('next-patent')
    eventBus.off('previous-patent')
    eventBus.off('close-patent')
})

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const getRowId = (params) => {
    return params.data.patentId
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const createServerSideDatasource = () => {
    const debouncedGetRows = debounce(async (params) => {
        let response
        if (patentViewerStore.ipBasicsObject) {
            response = await onGetPatentListIPBasics(params.request)
        } else {
            response = await onGetPatentList(params.request)
        }

        if (response && response.success) {
            params.success({ rowData: response.rows })
        } else {
            params.fail()
        }
    }, 1000)

    return {
        getRows: (params) => {
            debouncedGetRows(params)
        },
    }
}

const onGetPatentList = async (paramsFromTable) => {
    const fetchFunction = async (tableParams) => {
        const nodeParams = {
            nodeIds: patentViewerStore.nodeIdList,
            entityPks: patentViewerStore.entityPkList,
        }

        return patentViewerStore.listViewType === 'lit'
            ? await getLitPatentList(patentViewerStore.nodeIdList[0], tableParams)
            : await getPatentList(nodeParams, tableParams)
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const onGetPatentListIPBasics = async (paramsFromTable) => {
    const fetchFunction = async (tableParams) => {
        return await getPatentListIPBasics(
            patentViewerStore.ipBasicsEntityPK,
            patentViewerStore.ipBasicsObject,
            tableParams
        )
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const handlePatentListRequest = async (paramsFromTable, fetchFunction) => {
    loading.value = true

    const currentSortModel = paramsFromTable.sortModel || []
    const sortChanged = JSON.stringify(previousSortModel.value) !== JSON.stringify(currentSortModel)

    if (sortChanged) {
        tablePageNum.value = 0
        previousSortModel.value = [...currentSortModel]
        gridApi.value.refreshServerSide({ purge: true })
        return
    }

    try {
        tableParams.value = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )

        const data = await fetchFunction(tableParams.value)

        if (!totalPatents.value) {
            totalPatents.value = data.data.rowCount
            filteredPatents.value = data.data.rowCount
        } else {
            filteredPatents.value = data.data.rowCount
        }

        if (data.data.results.length === 0) {
            gridApi.value.showNoRowsOverlay()
            noRows.value = true
        }
        tablePageNum.value++
        return {
            success: true,
            rows: data.data.results,
        }
    } catch (error) {
        logger.error(error)
        return {
            success: false,
        }
    } finally {
        loading.value = false
    }
}

const onRowSelected = async (params) => {
    selectedPatentIndex.value = params.rowIndex
    if (params?.data?.patentId) {
        setTimeout(() => {
            patentViewerStore.targetPatentId = params.data.publicationPk
        }, 100)
    }
}

const onCellKeyDown = (params) => {
    selectedPatentIndex.value = params.rowIndex
    if (params.event.key === 'ArrowUp') {
        const previousRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (previousRow?.data?.patentId) {
            patentViewerStore.targetPatentId = previousRow.data.publicationPk
        }
    } else if (params.event.key === 'ArrowDown') {
        const nextRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (nextRow?.data?.patentId) {
            patentViewerStore.targetPatentId = nextRow.data.publicationPk
        }
    }
}

const closePatentList = () => {
    patentViewerStore.resetState()
}

const exportPatents = async () => {
    exportLoading.value = true

    let tableFilters = {
        'FC[]': tableParams.value.FC,
        'FT[]': tableParams.value.FT,
        'FV[]': tableParams.value.FV,
    }

    const exportParams = {
        requested_user: user.value.id,
        report_type: 'patent_export',
        requested_application: 'ip_alpha',
        report_name: patentViewerStore.ipBasicsObject
            ? `Patent Export - ${patentViewerStore.entityName} - ${patentViewerStore.ipBasicsObject.country} ${
                  patentViewerStore.ipBasicsObject.publicationType === 'A'
                      ? 'Applications'
                      : patentViewerStore.ipBasicsObject.publicationType === 'G'
                        ? 'Grants'
                        : 'Total'
              } ${patentViewerStore.ipBasicsObject.claimBreadth ? ' | ' + patentViewerStore.ipBasicsObject.claimBreadth : ''} - ${patentViewerStore.ipBasicsObject.validity ? patentViewerStore.ipBasicsObject.validity : ''}`
            : `Patent Export - ${patentViewerStore.entityName}${patentViewerStore.technologyName ? ` - ${patentViewerStore.technologyName}` : ''}`,
        report_input: {
            entityPks: patentViewerStore.entityPkList.map(Number),
            queryFilters: tableFilters,
        },
    }

    if (patentViewerStore.ipBasicsObject) {
        exportParams.report_input.entityPatentRequest = patentViewerStore.ipBasicsObject
    } else {
        exportParams.report_input.node_ids = patentViewerStore.nodeIdList
    }
    // console.log(exportParams)

    try {
        const { data } = await createReport(exportParams)
        submitReportForGeneration(data)
    } catch (err) {
        logger.error(err)
    }
}

const submitReportForGeneration = async (reportData) => {
    const params = {
        report_action: 'submit',
    }

    try {
        await patchReport(reportData.report_pk, params)
    } catch (err) {
        logger.error(err)
    } finally {
        exportLoading.value = false
        eventBus.emit('snacktime', {
            type: 'success',
            message:
                'The Export has been successfully submitted for generation. Feel free to make changes and submit another.',
            link: {
                text: 'Export',
                route: '/reports',
            },
            contextual: false,
        })
    }
}
</script>

<style lang="scss" scoped>
.ag-theme-quartz {
    --ag-active-color: #bcc9df;
}
.patent-list {
    z-index: 11 !important;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    transform: scale(0.9999) translate(-50%, -50%);

    :deep(.ag-row-focus) {
        background-color: #bcc9df !important;
    }

    :deep(.offset-1) {
        margin-left: 0 !important;
    }

    :deep(.aon-col) {
        padding: 10px !important;
    }

    :deep(.aon-col-10) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    :deep(.container) {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        max-height: none !important;
        box-shadow: 0px 0px 35px 10px rgba(0, 0, 0, 0.35) !important;
    }

    :deep(.modal-content) {
        height: 100% !important;
    }

    :deep(.background) {
        width: 110%;
        height: 110%;
        top: -3px;
        left: -3px;
    }

    :deep(.a-modal-close) {
        svg {
            color: white !important;
        }
    }

    .patent-list-header {
        min-height: 75px;
        width: 100%;
        border-bottom: 3px solid $grey03;

        .source-display {
            height: 100%;
        }
    }

    .divide {
        width: 2px;
        height: 20px;
        background-color: $grey04;
    }
}
</style>
