<template>
    <div class="world-view-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-5">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="World View"
                        desc="Explore the global footprint of the company's patent filings, including applications, grants, and asset inventories."
                    >
                        <template #header-append>
                            <div class="chart-action-wrapper d-flex align-items-end">
                                <p class="mr-3">Active Patents</p>
                                <AonToggleSwitch
                                    label="All Patents"
                                    label-end
                                    :uniqueID="'world-view-patents'"
                                    :switchToggled="!entityStore.ipBasicsActivePatents"
                                    @switch="
                                        entityStore.ipBasicsActivePatents =
                                            !entityStore.ipBasicsActivePatents
                                    "
                                />
                            </div>
                        </template>
                        <div class="card-content">
                            <AonSpinner
                                v-if="entityStore.loadingEntity"
                                class="ma-auto"
                                :scale="0.5"
                                color="castleMoat"
                            />
                            <PatentCharacteristicsWorld v-else />
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { useEntityStore } from '@/stores'

import PatentCharacteristicsWorld from './worldView/PatentCharacteristicsWorld.vue'

const entityStore = useEntityStore()
</script>

<style lang="scss" scoped>
.world-view-card {
    width: 100%;
    position: relative;
    :deep(.a-toggle-switch label) {
        background: $castleMoat !important;
    }
}
</style>
