import actions from './actions.json'
import auth from './auth.json'
import arenas from './arenas.json'
import arenaList from './arenaList.json'
import countries from './countries.json'
import companies from './companies.json'
import companySelection from './companySelection.json'
import competencies from './competencies.json'
import competitors from './competitors.json'
import contacts from './contacts.json'
import definitionTooltips from './definition-tooltips.json'
import entityHeader from './entity-header.json'
import entityHierarchy from './entity-hierarchy.json'
import entityDetails from './entity-details.json'
import entitySummary from './entity-summary.json'
import entityTypes from './entityTypes.json'
import error from './error.json'
import factset from './factset.json'
import feedbackForm from './feedback-form.json'
import form from './form.json'
import funding from './funding.json'
import global from './global.json'
import home from './home.json'
import innovationQuadrant from './innovation-quadrant.json'
import ipAlignment from './ip-alignment.json'
import ipLitigation from './ip-litigation.json'
import ipMetrics from './ip-metrics.json'
import jurisdictions from './jurisdictions.json'
import legalese from './legalese.json'
import licensingOpportunities from './licensing-opportunities.json'
import magicQuadrant from './magicQuadrant.json'
import market from './market.json'
import marketInnovationChart from './market-innovation-chart.json'
import marketMetrics from './marketMetrics.json'
import marketSummary from './marketSummary.json'
import markets from './markets.json'
import metrics from './metrics.json'
import notFound from './not-found.json'
import opensearch from './opensearch.json'
import passwordRules from './password-rules.json'
import patents from './patents.json'
import reports from './reports.json'
import revenueGrowthToPatent from './revenue-growth-to-patent.json'
import research from './research.json'
import screenerHeader from './screenerHeader.json'
import screeners from './screeners.json'
import search from './search.json'
import searchScreen from './searchScreen.json'
import snackTime from './snackTime.json'
import user from './user.json'
import unsavedChanges from './unsavedChanges.json'
import vendorRisk from './vendor-risk.json'
import risk from './risk.json'
import watchlists from './watchlists.json'
import productAlignment from './productAlignment.json'
import reportOutput from './reportOutput.json'
import insights from './insights.json'
import entityInnovator from './entityInnovator.json'

//Global
import address from './global/address.json'
import countriesWithDialingCodes from './global/countries.json'
import currency from './global/currency.json'
import dataTables from './global/dataTables.json'
import headerNav from './global/headerNav.json'
import labeledInput from './global/labeledInput.json'
import leftDrawer from './global/leftDrawer.json'
import mappings from './global/mappings.json'
import navigationText from './global/navigationText.json'
import phoneNumberInput from './global/phoneNumberInput.json'
import repeatedField from './global/repeatedField.json'
import request from './global/request.json'
import vendorComplianceRisk from './global/vendorComplianceRisk.json'
import standardTable from './global/standardTable.json'
import support from './global/support.json'
import llmApi from './global/llmApi.json'

//Forms
import formErrors from './forms/formErrors.json'
import labels from './forms/labels.json'

//Auth
import apps from './auth/apps.json'
import navDrawerAccount from './auth/nav-drawer-account.json'
import notifications from './auth/notifications.json'
import loginRedirect from './auth/login-redirect.json'
import permissionRedirect from './auth/permission-redirect.json'
import securityQuestion from './auth/securityQuestion.json'
import tos from './auth/tos.json'

export default {
    //Root directory
    actions,
    auth,
    arenas,
    arenaList,
    companies,
    companySelection,
    competencies,
    competitors,
    countries,
    definitionTooltips,
    entityDetails,
    entityHeader,
    entityHierarchy,
    entityTypes,
    entitySummary,
    contacts,
    error,
    factset,
    form,
    funding,
    global,
    home,
    innovationQuadrant,
    ipAlignment,
    ipLitigation,
    ipMetrics,
    jurisdictions,
    legalese,
    licensingOpportunities,
    magicQuadrant,
    market,
    marketInnovationChart,
    marketMetrics,
    marketSummary,
    markets,
    metrics,
    notFound,
    opensearch,
    passwordRules,
    patents,
    reports,
    research,
    revenueGrowthToPatent,
    screenerHeader,
    screeners,
    search,
    searchScreen,
    snackTime,
    standardTable,
    unsavedChanges,
    user,
    risk,
    watchlists,
    productAlignment,
    reportOutput,
    insights,
    //Global
    address,
    currency,
    dataTables,
    headerNav,
    labeledInput,
    leftDrawer,
    mappings,
    navigationText,
    phoneNumberInput,
    repeatedField,
    request,
    support,
    vendorComplianceRisk,
    countriesWithDialingCodes,
    //Forms
    formErrors,
    labels,
    //Auth
    apps,
    loginRedirect,
    navDrawerAccount,
    notifications,
    permissionRedirect,
    securityQuestion,
    tos,
    feedbackForm,
    llmApi,
    entityInnovator,
}
