import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePatentViewerStore = defineStore('patentViewer', () => {
    const nodeIdList = ref([])
    const entityPkList = ref([])
    const showPatentViewerList = ref(false)
    const entityName = ref('')
    const technologyName = ref('')
    const targetPatentId = ref(null)
    const patentDetailsOpen = ref(false)
    const patentDetails = ref(null)
    const listViewType = ref('')

    const ipBasicsObject = ref(null)
    const ipBasicsEntityPK = ref(null)

    const resetState = () => {
        nodeIdList.value = []
        entityPkList.value = []
        showPatentViewerList.value = false
        entityName.value = ''
        technologyName.value = ''
        targetPatentId.value = null
        patentDetailsOpen.value = false
        patentDetails.value = null
        listViewType.value = ''
        ipBasicsObject.value = null
        ipBasicsEntityPK.value = null
    }

    return {
        nodeIdList,
        entityPkList,
        showPatentViewerList,
        entityName,
        technologyName,
        targetPatentId,
        patentDetailsOpen,
        patentDetails,
        listViewType,
        ipBasicsObject,
        ipBasicsEntityPK,
        resetState,
    }
})
