<template>
    <div class="reports">
        <AonContainer add-page-padding class="knights-cloak">
            <AonRow>
                <AonCol class="a-col-12 pt-6 pb-16 px-0">
                    <div
                        class="header-holder d-flex w-full justify-content-between align-items-center"
                    >
                        <div class="header-copy-holder">
                            <h4 class="grey08--text">
                                {{ $t('reports.title') }}
                            </h4>
                            <p class="grey08--text">
                                Generate a report to get actionable insights across a variety of
                                report types
                            </p>
                        </div>
                        <AonButton :label="$t('reports.action.create')" @clicked="createReport" />
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="mt-n12">
            <MoatTable
                style="width: 100%; height: 86vh"
                :defaultColDef="defaultColDef"
                :class="themeClass"
                :column-defs="colDefs"
                :tooltip-interaction="true"
                :tooltip-mouse-track="true"
                :tooltip-show-delay="500"
                tooltip-show-mode="whenTruncated"
                row-model-type="serverSide"
                :cache-block-size="50"
                :max-blocks-in-cache="10"
                @grid-ready="gridInit"
            />
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, inject, computed, onBeforeMount } from 'vue'
import { useReportGenerationStore } from '@/stores/reportGeneration'
import { useRouter } from 'vue-router'
import { downloadFiles } from '@/composables/presignedUrlDownloader.js'
import { useI18n } from 'vue-i18n'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'
import * as ReportApi from '@/api/reports'
import { getReport } from '@/api/reports'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'

import { useAuth } from '@/auth/authPlugin'

const router = useRouter()
const reportGenerationStore = useReportGenerationStore()
const eventBus = inject('eventBus')
const { t } = useI18n()
const { user } = useAuth()
const filters = inject('filters')
const logger = inject('logger')

const gridApi = ref(null)
const searchTerm = ref('')
const tableData = ref([])
const tablePageSize = ref(50)
const tablePageNum = ref(0)
const defaultColDef = ref({})
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'report_name',
        headerName: 'Name',
        cellRenderer: 'MTCustomCellReportLink',
        cellRendererParams: {
            reportClicked: (params) => {
                onReportClicked(params)
            },
        },
        // onCellClicked: (params) => {
        //     onReportClicked(params)
        // },
        flex: 4,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'report_type',
        headerName: 'Report Type',
        valueFormatter: (p) => {
            if (p.data) {
                return t(`reports.type.${p.data.report_type}`)
            }
        },
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: [
                'product_alignment',
                'm_and_a',
                'head_to_head',
                'counter_assertion',
                'competitive_matchup',
                'licensing_opportunities',
                'patent_export',
            ],
            valueFormatter: (p) => {
                return t(`reports.type.${p.value}`)
            },
        },
    },
    {
        field: 'created_dt',
        headerName: 'Created Date',
        valueFormatter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.created_dt)
            }
        },
        sort: 'desc',
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            buttons: ['clear'],
            defaultOption: 'inRange',
            filterOptions: ['inRange'],
            maxNumConditions: 1,
        },
    },
    {
        field: 'report_status',
        headerName: 'Report Status',
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['complete', 'submitted', 'processing', 'saved', 'failed'],
            valueFormatter: (p) => {
                return reportStatusDisplay(p.value)
            },
        },
        valueFormatter: (p) => {
            if (p.data) {
                return reportStatusDisplay(p.data.report_status)
            }
        },
        flex: 1,
        cellClassRules: {
            'job-status-pill': () => true,
            'job-status-pill complete': (p) =>
                reportStatusDisplay(p.data.report_status) === 'Complete',
            'job-status-pill submitted': (p) =>
                reportStatusDisplay(p.data.report_status) === 'Submitted',
            'job-status-pill processing': (p) =>
                reportStatusDisplay(p.data.report_status) === 'Processing',
            'job-status-pill saved': (p) => reportStatusDisplay(p.data.report_status) === 'Saved',
            'job-status-pill failed': (p) => reportStatusDisplay(p.data.report_status) === 'Failed',
        },
    },
])

const loadingReports = ref(false)

onBeforeMount(() => {
    reportGenerationStore.resetState()
})

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const getReports = async (paramsFromTable) => {
    loadingReports.value = true
    try {
        let finalParams = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )
        const { data } = await ReportApi.getReports(finalParams, user.value.id)
        // Get entities from OS and process report results in parallel
        const entityPks = data.results
            .filter((res) => res.report_input?.target_entity)
            .map((item) => item.report_input.target_entity.toString())

        const osParams = {
            FC: ['aon_entity_pk'],
            FT: ['terms'],
            FV: entityPks,
        }

        const osQuery = getOpenSearchQuery(osParams)
        const entitiesResponse = await osApi.searchEntities(osQuery)

        const entitiesMap = new Map(
            entitiesResponse.data.hits.hits.map((entity) => [
                entity._source.aon_entity_pk,
                entity._source.name,
            ])
        )

        const results = data.results.map((item) => ({
            ...item,
            competitors: item.report_input?.competitors?.length || '0',
            target_entity_name: entitiesMap.get(item.report_input?.target_entity),
            display_status: t(`reports.status.${item.report_status}`),
            checked: false,
        }))

        tablePageNum.value++
        return {
            success: true,
            rows: results,
        }
    } catch (err) {
        logger.error(err)
        return {
            success: false,
        }
    } finally {
        loadingReports.value = false
    }
}

const createServerSideDatasource = () => {
    return {
        getRows: async (params) => {
            const response = await getReports(params.request)
            if (response.success) {
                params.success({ rowData: response.rows })
            } else {
                params.fail()
            }
        },
    }
}

const downloadReport = async (reportPk) => {
    try {
        const { data } = await getReport(reportPk)
        downloadFiles([
            {
                url: data.report_output.artifact_presigned_url,
                name: data.report_name,
            },
        ])
        eventBus.emit('report-loading', { reportPk: reportPk, loading: false })
    } catch (err) {
        eventBus.emit('snacktime', {
            type: 'error',
            message: t('reports.errors.download'),
        })
        eventBus.emit('report-loading', { reportPk: reportPk, loading: false })
        return
    }
}
const reportStatusDisplay = (status) => {
    return t(`reports.status.${status}`)
}

const onReportClicked = (params) => {
    const reportPk = params.data.report_pk
    downloadReport(reportPk)
}

const createReport = () => {
    router.push({ name: 'Create Report' })
}
</script>

<style lang="scss" scoped>
.reports {
    min-height: 100vh;
    height: 100%;

    :deep(.ag-theme-quartz) {
        .job-status-pill {
            width: auto !important;
            border-radius: 4px;
            background: #ac6e00; // This is for the default pill-type class will look like '.pending'
            color: #fdf1d6;
            line-height: 1.3em;
            height: 30px;
            margin-top: 5px;
            margin-left: 10px;

            display: flex;
            align-items: center;
            align-self: center;

            &.complete {
                background: #12a88a;
                color: #cddbde;
            }
            &.submitted {
                background: #ffa600;
                color: #cddbde;
            }
            &.processing {
                background: #0084bb;
                color: #cddbde;
            }
            &.saved {
                background: #003c55;
                color: #cddbde;
            }
            &.failed {
                background: #822300;
                color: #cddbde;
            }
        }
    }
}
</style>
