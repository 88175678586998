import axios from 'axios'
import { searchWrapper } from './search'

const baseUrl = 'api/entities'

export function getEntityMarketIpAlignment(entityId, marketId) {
    return axios.get(`${baseUrl}/${entityId}/ip-alignment/${marketId}`)
}

export function getEntityMarkets(entityId, page, pageSize, orderBy, orderDesc, searchText) {
    let endpoint = `${baseUrl}/${entityId}/sector-hierarchy?page=${page}&pageSize=${pageSize}&searchText=${searchText}`

    if (orderBy) {
        endpoint += `&orderBy=${orderBy}&orderDesc=${orderDesc}`
    }

    return axios.get(endpoint)
}

export function queryEntitySectors(params, entityId) {
    return axios.get(`${baseUrl}/${entityId}/sectors/query`, {
        params,
        paramsSerializer: { indexes: null },
    })
}

export function getEntityMarket(entityId, sectorId) {
    return axios.get(`${baseUrl}/${entityId}/sectors/${sectorId}`)
}

export function search(searchTerm, params) {
    return searchWrapper(`${baseUrl}/search`, {
        ...params,
        entityName: searchTerm,
    })
}

export function getEntitySectorYearlyRevenue(entityId, sectorId) {
    return axios.get(`${baseUrl}/${entityId}/sectors/${sectorId}/cagr`)
}

export function getRevenueGrowthToPatentReportData(entityId, sectorId) {
    return axios.get(`${baseUrl}/${entityId}/sectors/${sectorId}/revenue-to-patent-report`)
}

export function getEntityFunding(entityId) {
    return axios.get(`${baseUrl}/${entityId}/funding`)
}

export function getEntityRankedPatentTotals(entityId, sectorIds) {
    const params = {
        aonSectorPks: sectorIds,
    }
    return axios.get(`${baseUrl}/${entityId}/ranked-patent-totals`, { params })
}

export function getEntityMoatQualityMetrics(entityId) {
    return axios.get(`${baseUrl}/${entityId}/moat_quality_metrics`)
}

export function getEntitySectorMoatQualityMetrics(entityId, passedParams) {
    let params
    if (passedParams) {
        params = passedParams
    } else {
        params = {
            SC: [],
            SD: [],
            SP: null,
            FD: [null],
            FC: ['is_participating'],
            FT: ['eq'],
            FV: ['True'],
            page_size: 20,
            last_row_num: 0,
            page: 1,
        }
        return axios.get(`${baseUrl}/${entityId}/sector_moat_quality_metrics`, {
            params,
            paramsSerializer: { indexes: null },
        })
    }
}

export function getEntityPortfolioValidity(entityId, country) {
    return axios.get(`${baseUrl}/${entityId}/portfolio-validity`, {
        params: {
            jurisdiction: country,
        },
    })
}

export function getEntityValidityByClaimScore(entityId) {
    return axios.get(`${baseUrl}/${entityId}/validity_by_claim_score`)
}

export function getEntityPatentCountHistoryByCountry(entityId, country) {
    return axios.get(`${baseUrl}/${entityId}/patent-count/history`, {
        params: {
            country: country,
            years: 8,
        },
    })
}

export function getEntityCumulativePatentCountHistoryByCountry(entityId, country) {
    return axios.get(`${baseUrl}/${entityId}/patent-count/cumulative-history`, {
        params: {
            country: country,
            years: 8,
        },
    })
}

export function getEntityPatentFamilyStats(entityId) {
    return axios.get(`${baseUrl}/${entityId}/patent-family-stats`)
}

export function getFinancialOverview(entityId) {
    return axios.get(`${baseUrl}/${entityId}/financial_overview`)
}

export function getIpValue(entityId) {
    return axios.get(`${baseUrl}/${entityId}/ip_valuation`)
}

export function getIpRebuildCost(entityId) {
    return axios.get(`${baseUrl}/${entityId}/ip_rebuild_cost`)
}

export function getPatentBreakdown(entityId) {
    return axios.get(`${baseUrl}/${entityId}/patent-breakdown`)
}

export function computeEntityCompetitors(entityId) {
    return axios.post(`${baseUrl}/${entityId}/competitors`)
}

export function computeEntityAcquisitionTargets(entityId) {
    return axios.post(`${baseUrl}/${entityId}/acquisition_targets`)
}

export function computePotentialBuyers(entityId) {
    return axios.post(`${baseUrl}/${entityId}/potential_buyers`)
}

export function getInsights(entityId) {
    return axios.get(`${baseUrl}/${entityId}/insights`)
}

export function getCrunchbaseRank(entityId) {
    return axios.get(`${baseUrl}/${entityId}/crunchbase`)
}

export function getBusinessSegmentRev(entityId) {
    return axios.get(`${baseUrl}/${entityId}/business_segment_revenue`)
}

export function getEntityAcquisitions(entityId) {
    const params = {
        page_size: 1000,
    }
    return axios.get(`${baseUrl}/${entityId}/acquisitions`, { params })
}

export function getEntityLicensingOpportunities(entityId) {
    return axios.get(`${baseUrl}/${entityId}/licensing_opportunities`)
}
