<template>
    <div class="patent-details" ref="patentDetailsRef" tabindex="0">
        <AonCoverLoading :loading="loadingDetails" title="Loading Patent Details..." />
        <div class="nav-tab d-flex flex-column align-items-center">
            <font-awesome-icon
                :icon="`fas fa-xmark`"
                class="pa-2 px-2 grey05 patent-nav close"
                v-tooltip="{
                    content: 'Close',
                    placement: 'left',
                    delay: { show: 500, hide: 0 },
                }"
                tabindex="0"
                role="button"
                aria-label="Close"
                aria-expanded="false"
                @click="closeDetails"
            />
            <div class="divide hor"></div>
            <font-awesome-icon
                :icon="`fas fa-arrow-left-to-line`"
                class="pa-2 mb-2 grey05 patent-nav up"
                v-tooltip="{
                    content: 'Previous Patent',
                    placement: 'left',
                    delay: { show: 500, hide: 0 },
                }"
                @click="previousPatent"
            />
            <font-awesome-icon
                :icon="`fas fa-arrow-right-to-line`"
                class="pa-2 grey05 patent-nav down"
                v-tooltip="{
                    content: 'Next Patent',
                    placement: 'left',
                    delay: { show: 500, hide: 0 },
                }"
                @click="nextPatent"
            />
        </div>
        <div class="contain-shadow">
            <div class="high-level d-flex align-items-center justify-content-between px-4">
                <div class="meta-holder d-flex align-items-center py-2">
                    <p
                        class="bold link"
                        @click="
                            goToGooglePatents(patentViewerStore.patentDetails?.googlePatentsLink)
                        "
                    >
                        {{ patentViewerStore.patentDetails?.patentId }}
                    </p>
                    <div class="divide"></div>
                    <div class="status d-flex align-items-center">
                        <div
                            class="status-dot"
                            :class="
                                patentViewerStore.patentDetails?.status === 'Active'
                                    ? 'success'
                                    : 'grey03'
                            "
                        ></div>
                        <p class="dungeonDepths--text mx-2">
                            {{
                                patentViewerStore.patentDetails?.status === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }}
                        </p>
                    </div>
                    <div class="divide"></div>
                    <!-- <div class="divide"></div> -->
                    <p class="bold dungeonDepths--text">
                        {{ patentViewerStore.patentDetails?.applicationNumber }}
                    </p>
                </div>
                <!-- <div class="nav-holder py-2 pl-4 d-flex align-items-center">
                    <font-awesome-icon
                        :icon="`fas fa-arrow-left-to-line`"
                        class="mr-3 pa-2 grey05 patent-nav up"
                        @click="previousPatent"
                    />
                    <font-awesome-icon
                        :icon="`fas fa-arrow-right-to-line`"
                        class="pa-2 grey05 patent-nav down"
                        @click="nextPatent"
                    />
                </div> -->
            </div>
            <div class="overflow-container">
                <div class="cpc-holder px-4 pt-2">
                    <p class="small dungeonDepths--text">
                        <span class="mr-2" style="font-weight: 700">CPC:</span
                        >{{ patentViewerStore.patentDetails?.cpcCodes }}
                    </p>
                </div>
                <div class="title mt-3 mb-2 px-4">
                    <p class="bold grey01--text large">
                        {{ patentViewerStore.patentDetails?.title }}
                    </p>
                </div>
                <div class="assignee d-flex mb-4 px-4">
                    <div class="mt-2 w-half">
                        <p class="section-header bold dungeonDepths--text mb-1">Current Assignee</p>
                        <p class="no-wrap overflow-ellipsis">
                            {{ patentViewerStore.patentDetails?.entity }}
                        </p>
                    </div>
                    <div class="mt-2 w-half">
                        <p class="section-header bold dungeonDepths--text mb-1">Ultimate Parent</p>
                        <p class="no-wrap overflow-ellipsis">
                            {{ patentViewerStore.patentDetails?.ultimateEntity }}
                        </p>
                    </div>
                </div>
                <div class="dates px-4 mb-4">
                    <p class="section-header bold dungeonDepths--text mb-1">Important Dates</p>
                    <div class="cases-wrapper d-flex align-items-center mt-2">
                        <div class="my-1 case-stat mr-6 pr-6" style="width: 33%">
                            <p class="grey02--text bold align-left">Filing Date:</p>
                            <p class="mt-1">
                                {{
                                    filters.toUTCString(patentViewerStore.patentDetails?.filingDate)
                                }}
                            </p>
                        </div>
                        <div class="my-1 case-stat mr-6 pr-6" style="width: 33%">
                            <p class="grey02--text bold align-left">Publication Date:</p>
                            <p class="mt-1">
                                {{
                                    filters.toUTCString(
                                        patentViewerStore.patentDetails?.publicationDate
                                    )
                                }}
                            </p>
                        </div>
                        <div class="my-1 case-stat" style="width: 33%">
                            <p class="grey02--text bold align-left">Priority Date:</p>
                            <p class="mt-1">
                                {{
                                    filters.toUTCString(
                                        patentViewerStore.patentDetails?.priorityDate
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="bottom-divide"></div>

                <div class="section px-4 mt-4">
                    <p class="section-header bold dungeonDepths--text mb-1">Abstract</p>
                    <div class="abstract-holder d-inline">
                        <p v-if="!showAllAbstract">
                            {{ trimmedAbstract }}
                            <span class="link small" @click="showAllAbstract = !showAllAbstract">
                                Show More
                            </span>
                        </p>
                        <p v-else>
                            {{ patentViewerStore.patentDetails?.abstract }}
                            <span class="link small" @click="showAllAbstract = !showAllAbstract">
                                Show Less
                            </span>
                        </p>
                    </div>
                </div>
                <!-- <div class="section px-4 my-6">
                    <p class="section-header bold dungeonDepths--text mb-1">Inventors</p>
                    <p v-if="!patentViewerStore.patentDetails?.inventors">No Inventors Found</p>
                    <div
                        v-else
                        v-for="inventor in patentViewerStore.patentDetails?.inventors"
                        class="inventor-wrap d-flex"
                    >
                        <p class="link mr-2">{{ inventor.name }}</p>
                    </div>
                </div> -->
                <div class="section px-4 d-flex my-6">
                    <div class="w-half">
                        <p
                            class="section-header bold dungeonDepths--text mb-1 tooltip-indicator d-inline-block"
                        >
                            <VDropdown
                                placement="top"
                                :triggers="['hover']"
                                :popper-triggers="['hover']"
                                aria-hidden="false"
                            >
                                <span>Claim Breadth</span>
                                <template #popper>
                                    <div class="popover-container pa-2">
                                        {{ t('definitionTooltips.patentClaimBreadth.definition') }}
                                    </div>
                                </template>
                            </VDropdown>
                        </p>
                        <div class="inventor-wrap d-flex">
                            <p class="">{{ patentViewerStore.patentDetails?.claimBreadth }}</p>
                        </div>
                    </div>
                    <div class="w-half">
                        <p
                            class="section-header bold dungeonDepths--text mb-1 tooltip-indicator d-inline-block"
                        >
                            <VDropdown
                                placement="top"
                                :triggers="['hover']"
                                :popper-triggers="['hover']"
                            >
                                <span>Validity Score</span>
                                <template #popper>
                                    <div class="popover-container pa-2">
                                        {{ t('definitionTooltips.validity.definition') }}
                                    </div>
                                </template>
                            </VDropdown>
                        </p>
                        <div class="inventor-wrap d-flex">
                            <p class="">{{ patentViewerStore.patentDetails?.validity }}</p>
                        </div>
                    </div>
                </div>

                <div class="cases px-4 pb-4 my-4">
                    <p class="section-header bold dungeonDepths--text mb-1">Litigation Cases</p>
                    <div class="cases-wrapper d-flex align-items-center mt-2">
                        <div class="my-1 case-stat mr-6 pr-6" style="width: 33%">
                            <p class="grey02--text bold align-left">PTAB:</p>
                            <p class="mt-1">
                                {{
                                    patentViewerStore.patentDetails?.ptabCaseCount.toLocaleString()
                                }}
                            </p>
                        </div>
                        <div class="my-1 case-stat mr-6 pr-6" style="width: 33%">
                            <p class="grey02--text bold align-left">ITC:</p>
                            <p class="mt-1">
                                {{ patentViewerStore.patentDetails?.itcCaseCount.toLocaleString() }}
                            </p>
                        </div>
                        <div class="my-1 case-stat" style="width: 33%">
                            <p class="grey02--text bold align-left">DC:</p>
                            <p class="mt-1">
                                {{
                                    patentViewerStore.patentDetails?.patentDcCaseCount.toLocaleString()
                                }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accor-panel">
                    <AonAccordionPanel :key="stateResetKey" class="accordion px-4">
                        <template #header>
                            <div class="header-holder py-4">
                                <p class="bold">Claims</p>
                            </div>
                        </template>
                        <template #body>
                            <p class="py-4" v-html="patentViewerStore.patentDetails?.claims"></p>
                        </template>
                    </AonAccordionPanel>
                    <AonAccordionPanel :key="stateResetKey" class="accordion px-4">
                        <template #header>
                            <div class="header-holder py-4">
                                <p class="bold">Patent Family Members</p>
                            </div>
                        </template>
                        <template #body>
                            <div class="family-holder d-flex flex-wrap py-4">
                                <div
                                    v-for="patent in patentViewerStore.patentDetails?.familyPatents"
                                    :key="patent.patentId"
                                    class="slide-item mt-2 d-flex justify-content-center w-25"
                                    @click="goToGooglePatents(patent.googlePatentsLink)"
                                >
                                    <p class="link no-wrap bold center-align">
                                        {{ patent.patentId }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </AonAccordionPanel>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { usePatentViewerStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash'

import { getPatentDetails } from '@/api/patentViewer.js'

import { AonAccordionPanel } from '@moatmetrics/armory/src/components'

const patentViewerStore = usePatentViewerStore()

const logger = inject('logger')
const filters = inject('filters')
const eventBus = inject('eventBus')
const { t } = useI18n()

const patentDetailsRef = ref(null)
const loadingDetails = ref(false)
const showAllAbstract = ref(false)
const stateResetKey = ref(0)

const setLoadingFalse = debounce(() => {
    loadingDetails.value = false
}, 1000)

onMounted(() => {
    window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyPress)
})

watch(
    () => patentViewerStore.targetPatentId,
    (newVal, oldVal) => {
        if (!newVal) {
            patentViewerStore.patentDetailsOpen = false
            return
        }

        if (newVal !== oldVal) {
            patentViewerStore.patentDetailsOpen = true
            onGetPatentDetails(newVal)
        }
    }
)

watch(
    () => patentViewerStore.patentDetailsOpen,
    (isOpen) => {
        if (isOpen) {
            patentDetailsRef.value?.focus()
        } else {
            patentViewerStore.patentDetails = null
        }
    }
)

const trimmedAbstract = computed(() => {
    if (!patentViewerStore.patentDetails?.abstract) return ''

    if (patentViewerStore.patentDetails?.abstract.length > 500) {
        return `${patentViewerStore.patentDetails?.abstract.slice(0, 500).replace(/\\n/g, ' ').trimEnd()}...`
    } else {
        return `${patentViewerStore.patentDetails?.abstract.slice(0, 500).replace(/\\n/g, ' ').trimEnd()}`
    }
})

const onGetPatentDetails = async (patentId) => {
    loadingDetails.value = true
    stateResetKey.value++
    try {
        const { data } = await getPatentDetails(patentId)
        patentViewerStore.patentDetails = data

        // Scroll the overflow container back to top
        const overflowContainer = document.querySelector('.overflow-container')
        if (overflowContainer) {
            overflowContainer.scrollTop = 0
        }
    } catch (err) {
        // Only log errors that aren't cancellation errors
        if (err.code !== 'ERR_CANCELED') {
            logger.error(err)
        }
        if (err.code === 'ERR_CANCELED') {
            loadingDetails.value = true
        }
    } finally {
        setLoadingFalse()
    }
}

const previousPatent = () => {
    eventBus.emit('previous-patent')
}

const nextPatent = () => {
    eventBus.emit('next-patent')
}

const closeDetails = async () => {
    if (loadingDetails.value) {
        await getPatentDetails(null)
    }
    eventBus.emit('close-patent')
    patentViewerStore.patentDetailsOpen = false
}

const handleKeyPress = (event) => {
    if (!patentViewerStore.patentDetailsOpen) return

    if (!patentDetailsRef.value?.contains(document.activeElement)) return

    switch (event.key) {
        case 'ArrowUp':
            previousPatent()
            break
        case 'ArrowDown':
            nextPatent()
            break
    }
}

const goToGooglePatents = (googleLink) => {
    window.open(`${googleLink}`, '_blank')
}
</script>

<style lang="scss" scoped>
$patentHeaderHeight: 75px;
.patent-details {
    width: 50vw;
    height: calc(100% - $patentHeaderHeight);
    background-color: white;
    border-left: 1px solid $grey03;
    box-shadow: -8px 0px 76px 0px rgba(0, 0, 0, 0.55);

    position: fixed;
    z-index: 12;
    right: 0;
    top: $patentHeaderHeight;

    &:focus {
        outline: none;
    }

    .accor-panel {
        p {
            white-space: pre-line;
        }

        .accordion {
            border-bottom: 1px solid $grey03;

            &:first-of-type {
                border-top: 1px solid $grey03;
            }
        }
    }

    .overflow-container {
        height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .nav-tab {
        padding: 10px;
        background: white;
        border-left: 1px solid $grey03;
        border-bottom: 1px solid $grey03;
        border-top: 1px solid $grey03;
        border-radius: 4px 0 0 4px;
        overflow: hidden;

        position: absolute;
        z-index: 0;
        top: 75px;
        left: -61px;

        .patent-nav {
            width: 20px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            // border: solid 1px $grey03;
            padding: 10px !important;

            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $grey04;
            }

            &.up {
                transform: rotate(90deg);
            }

            &.down {
                transform: rotate(90deg);
            }
        }
    }

    .nav-holder {
        border-left: 1px solid $grey03;
    }

    .high-level {
        height: 40px;
        border-bottom: 1px solid $grey03;
    }

    .contain-shadow {
        height: 100%;
        width: 100%;
        overflow: hidden;

        position: relative;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .divide {
        width: 2px;
        height: 20px;
        background-color: $grey03;
        margin: 0 12px;

        &.hor {
            width: 25px;
            height: 2px;
            margin: 12px 0;
        }
    }

    .bottom-divide {
        width: 100%;
        height: 1px;
        background-color: $grey03;
        margin-top: 10px;
    }

    .status-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .case-stat {
        border-right: 1px solid $grey03;

        &:last-child {
            border-right: none;
        }
    }
}
</style>
