<template>
    <div class="licensing-opportunities-output d-flex flex-column">
        <AonCoverLoading
            :loading="loading"
            title="Loading Licensing Opportunities Playbook..."
        ></AonCoverLoading>
        <div class="output-header knights-cloak pa-4">
            <h5 class="gold--text">Licensing Opportunities - {{ target }}</h5>
        </div>
        <div class="action-holder d-flex justify-content-between align-items-center my-5">
            <div class="buttons d-flex">
                <AonButton
                    class="mr-2 filter-button"
                    label="Litigation Risks"
                    :type="selectedLicType === 'litigation_risk' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-gavel' }"
                    @clicked="selectLicensingOpType('litigation_risk')"
                />
                <AonButton
                    class="mr-2 filter-button"
                    label="Emerging Competitors"
                    :type="selectedLicType === 'emerging_competitor' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-shuffle' }"
                    @clicked="selectLicensingOpType('emerging_competitor')"
                />
                <AonButton
                    class="mr-2 filter-button"
                    label="Startup Opportunities"
                    :type="selectedLicType === 'startup' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-seedling' }"
                    @clicked="selectLicensingOpType('startup')"
                />
                <AonButton
                    class="mr-2 filter-button"
                    label="Expiring Portfolios"
                    :type="selectedLicType === 'expiring_portfolio' ? 'prominent' : 'subtle'"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-hourglass-end' }"
                    @clicked="selectLicensingOpType('expiring_portfolio')"
                />
            </div>
            <div>
                <AonButton
                    class="pl-5 target-button"
                    label="Change Licensing Party"
                    type="subtle"
                    :text-with-icon="true"
                    :icon-options="{ iconStyle: 'fas', iconName: 'fa-bullseye' }"
                    @clicked="gotoStep(true, 2)"
                />
            </div>
        </div>
        <div class="mb-5 license-op-type-description">
            <p>{{ licDescription }}</p>
        </div>

        <MoatTable
            class="table-border w-full flex-grow-1"
            :key="updater"
            :class="themeClass"
            :column-defs="colDefs"
            :row-data="tableData"
            :tooltip-show-delay="500"
            @grid-ready="gridInit"
        />
    </div>
</template>

<script setup>
import { ref, inject, onActivated, nextTick, computed, onBeforeMount } from 'vue'
import { usePlaybookGenerationStore, usePatentViewerStore } from '@/stores'
import { getEntityLicensingOpportunities } from '@/api/entities'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { useFlag } from '@unleash/proxy-client-vue'
import { filter } from 'd3'
import { useI18n } from 'vue-i18n'
import { getInvestmentGroup } from '@/lib/investments'
const filters = inject('filters')
const showPatentList = useFlag('ipAlpha.showPatentList')
const { t } = useI18n()
const logger = inject('logger')
const playbookGenerationStore = usePlaybookGenerationStore()
const patentViewerStore = usePatentViewerStore()
const props = defineProps({
    flexTable: {
        type: Boolean,
        default: false,
    },
})

const updater = ref(0)
const loading = ref(true)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const target = ref(null)
const targetEntityPk = ref(null)
const allLicensingOpportunities = ref([])
const selectedLicType = ref(null)
const tableData = ref([])
const colDefs = ref([])

const baseColDefs = ref([
    {
        field: 'licenseeRank',
        headerName: 'Rank',
        width: 100,
        minWidth: 100,
        pinned: 'left',
        lockPosition: true,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'entityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 300,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        pinned: 'left',
        lockPosition: true,
        chartDataType: 'category',
    },
    {
        field: 'displayName',
        headerName: 'Product',
        filter: 'agSetColumnFilter',
        flex: 3,
        minWidth: 250,
    },
])

const litigationColDefs = ref([
    {
        field: 'nodeRiskScore',
        headerName: 'Risk Score',
        minWidth: 130,
        width: 130,
    },
    {
        field: 'nodeCaseCount',
        headerName: 'Case Count',
        filter: 'agNumberColumnFilter',
        minWidth: 160,
        width: 160,
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        flex: 2,
        minWidth: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'innovationScoreRequirement',
        headerName: 'Innovation Score Target',
        filter: 'agNumberColumnFilter',
        flex: 2,
        minWidth: 250,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScoreRequirement.toFixed(0)).toLocaleString()}`
            }
        },
    },
])

const emergingContenderColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'opportunity',
        headerName: 'Momentum',
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
])

const startupColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        width: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'opportunity',
        headerName: 'Momentum',
        filter: 'agNumberColumnFilter',
        minWidth: 160,
        width: 160,
        valueFormatter: (p) => {
            if (p.data) {
                return Math.round(p.data.opportunity)
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        minWidth: 200,
        width: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'ventureCapitalInvestmentAmount',
        headerName: 'Venture Capital Investment',
        filter: 'agNumberColumnFilter',
        minWidth: 275,
        valueFormatter: (p) => {
            if (p.data) {
                return `$${parseInt(p.data.ventureCapitalInvestmentAmount.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'lastFundingOn',
        headerName: 'Last Funding Date',
        filter: 'agDateColumnFilter',
        minWidth: 200,
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.lastFundingOn)
            }
        },
    },
    {
        field: 'lastFundingRoundInvestmentType',
        headerName: 'Last Funding Investment Type',
        filter: 'agSetColumnFilter',
        minWidth: 300,
        valueGetter: (p) => {
            if (p.data) {
                return getInvestmentGroup(p.data.lastFundingRoundInvestmentType)
            }
        },
    },
])

const expiringColDefs = ref([
    {
        field: 'portfolioFocusPercent',
        headerName: 'Focus',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        width: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.portfolioFocusPercent.toFixed(0)}%`
            }
        },
    },
    {
        field: 'innovationScore',
        headerName: 'Innovation Score',
        filter: 'agNumberColumnFilter',
        minWidth: 200,
        width: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return `${parseInt(p.data.innovationScore.toFixed(0)).toLocaleString()}`
            }
        },
    },
    {
        field: 'fiveYearExpirationPercent',
        headerName: '5 Year Expiration %',
        filter: 'agNumberColumnFilter',
        minWidth: 250,
        valueGetter: (p) => {
            if (p.data) {
                return `${p.data.fiveYearExpirationPercent.toFixed(0)}%`
            }
        },
    },
])

onBeforeMount(async () => {})

onActivated(async () => {
    target.value = playbookGenerationStore.playbookSelections[1].selectionLabel
    targetEntityPk.value = parseInt(playbookGenerationStore.playbookSelections[1].value)
    loadLicensingOpportunities()
    //loading.value = false
})

const gridInit = (params) => {
    gridApi.value = params.api
}

const loadLicensingOpportunities = async () => {
    loading.value = true

    try {
        let { data } = await getEntityLicensingOpportunities(targetEntityPk.value)
        allLicensingOpportunities.value = data
        selectLicensingOpType('litigation_risk')
    } catch (error) {
        logger.error(error)
    } finally {
        loading.value = false
    }
}

const pushContextMenuToTable = (params) => {
    if (showPatentList.value) {
        colDefs.value.push({
            suppressHeaderMenuButton: true,
            cellRenderer: 'MTContextMenu',
            cellRendererParams: {
                actionsList: [
                    {
                        name: `View ${target.value} Patents`,
                        icon: 'fa-square-list',
                        actionName: 'patents',
                        patentsAction: async (params) => {
                            patentViewerStore.nodeIdList = [params.data.nodeId]
                            patentViewerStore.technologyName = params.data.displayName
                            patentViewerStore.entityPkList = [targetEntityPk.value]
                            patentViewerStore.entityName = target.value
                            await nextTick()
                            patentViewerStore.showPatentViewerList = true
                        },
                    },
                    {
                        name: 'View Contender Patents',
                        icon: 'fa-square-list',
                        actionName: 'contenderPatents',
                        contenderPatentsAction: async (params) => {
                            console.log(params)
                            patentViewerStore.nodeIdList = [params.data.nodeId]
                            patentViewerStore.technologyName = params.data.displayName
                            patentViewerStore.entityPkList = [params.data.entityPk]
                            patentViewerStore.entityName = params.data.entityName
                            await nextTick()
                            patentViewerStore.showPatentViewerList = true
                        },
                    },
                ],
                highlightLabel: true,
            },
            pinned: 'right',
            width: 42,
        })
    }
}

const selectLicensingOpType = (licOpType) => {
    selectedLicType.value = licOpType
    colDefs.value = baseColDefs.value.concat(licOpTypes[licOpType].colDefs.value)
    tableData.value = allLicensingOpportunities.value.filter(
        (item) => item.licenseeReason === licOpType
    )
    pushContextMenuToTable()
}

const gotoStep = (allowEdit = true, stepNum) => {
    if (!allowEdit) {
        return
    }
    playbookGenerationStore.setCurrentStep(stepNum)
}

const licDescription = computed(() => {
    if (!selectedLicType.value) {
        return null
    }
    return t(`licensingOpportunities.${selectedLicType.value}.description`)
})

// constants
const licOpTypes = {
    litigation_risk: {
        label: 'Litigation Risks',
        colDefs: litigationColDefs,
    },
    emerging_competitor: {
        label: 'Emerging Competitors',
        colDefs: emergingContenderColDefs,
    },
    startup: {
        label: 'Startup Opportunities',
        colDefs: startupColDefs,
    },
    expiring_portfolio: {
        label: 'Expiring Portfolios',
        colDefs: expiringColDefs,
    },
}
</script>

<style lang="scss" scoped>
.licensing-opportunities-output {
    height: 100%;
    width: 100%;

    .table-border {
        border: 1px solid $grey04;
        border-radius: 8px;
    }

    .expand-collapse-button {
        cursor: pointer;
        border: 1px solid $grey01;
        border-radius: 4px;

        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $grey01;

            svg {
                color: white !important;
            }
        }
    }

    :deep(.target-header) {
        background-color: rgba($castleMoat, 0.8) !important;
        color: white !important;
    }

    .filter-button {
        :deep(.a-button) {
            font-weight: 600px;
            font-size: 14px;
            padding: 8px;
        }
    }

    .target-button {
        border-left: 2px solid $grey01;

        :deep(.a-button) {
            background-color: rgba($castleMoat, 0.8) !important;
            border-radius: 4px;
        }

        :deep(span) {
            color: white !important;
            fill: white !important;
        }

        :deep(svg) {
            color: white !important;
            fill: white !important;
        }
    }

    :deep(.comp-header) {
        background-color: $grey05 !important;
    }
}
</style>
