<template>
    <div class="playbook-creation-type-selection">
        <div class="radio-container d-flex flex-wrap">
            <AonCard
                v-for="item in typeOptions"
                class="type-option mb-5"
                :class="{
                    selected: playbookGenerationStore.playbookSelections[0]?.value === item.value,
                }"
                :key="item.label + '_key'"
                @click="setPlaybookType(item.value, item.label)"
            >
                <div class="content-holder d-flex align-items-start">
                    <div class="icon-holder">
                        <div
                            :id="item.iconID"
                            class="type-option-icon"
                            style="width: 50px; height: 50px; background-size: contain"
                        ></div>
                        <img
                            src="@/assets/svgs/report/checkmark-circle.svg"
                            class="type-option-icon checkmark"
                        />
                    </div>
                    <div class="label-wrapper ml-4">
                        <p class="label bold" style="text-wrap: nowrap">{{ item.label }}</p>
                        <p class="mt-1">{{ item.description }}</p>
                    </div>
                </div>
            </AonCard>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick, onBeforeMount } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'

import gloves from '@/assets/icon/gloves.png'
import gavelPaper from '@/assets/icon/gavel-paper.png'
import handshake from '@/assets/icon/handshake.png'

const showLicensingOpportunitiesPlaybook = useFlag('ipAlpha.LicensingOpportunitiesPlaybook')

const playbookGenerationStore = usePlaybookGenerationStore()

const typeOptions = ref([
    {
        label: 'Competitive Matchup',
        description:
            'Identifies the technology areas where the companies you select have innovation coverage. Uncover who has more coverage in an area than another.',
        iconID: 'head-to-head-icon',
        value: 'matchUp',
    },
    {
        label: 'Counter Assertion',
        description:
            'Identify technology areas where one party can assert counterclaims against another during litigation. This process focuses on situations where the initiating party has filed a legal action (e.g., alleging patent infringement), and the responding party may defend or retaliate by asserting patents that overlap the initiating parties products.',
        iconID: 'ip-type-icon',
        value: 'counterAssertion',
    },
])

onMounted(async () => {
    // Example for new incoming playbooks

    if (showLicensingOpportunitiesPlaybook.value) {
        typeOptions.value.push({
            label: 'Licensing Opportunities',
            description:
                'Discover licensing opportunities by analyzing coverage gaps in peer patent portfolios.',
            iconID: 'licensing-opportunities-icon',
            value: 'licensingOpportunities',
        })
        await nextTick()
        document.getElementById('licensing-opportunities-icon').style.backgroundImage =
            `url("${handshake}")`
    }
    await nextTick()
    document.getElementById('ip-type-icon').style.backgroundImage = `url("${gavelPaper}")`
    document.getElementById('head-to-head-icon').style.backgroundImage = `url("${gloves}")`
})

const setPlaybookType = async (val, label) => {
    playbookGenerationStore.setPlaybookType(val, label)
}
</script>

<style lang="scss" scoped>
.playbook-creation-type-selection {
    height: 100%;
    width: 100%;

    .type-option {
        cursor: pointer;
        outline: solid transparent 3px;
        width: 100%;
        // height: 90px;

        transition: all 0.3s ease-in-out;

        // &:nth-last-child(-n + 2) {
        //     margin-bottom: 0 !important;
        // }

        // &:nth-child(odd) {
        //     margin-left: 0 !important;
        // }

        // &:nth-child(even) {
        //     margin-right: 0 !important;
        // }

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        &:hover {
            background: $grey05;
        }

        &.selected {
            border-color: $success;
            outline-color: $success;

            .label {
                color: $castleMoat;
            }

            .icon-holder {
                .type-option-icon {
                    opacity: 0;
                }

                .checkmark {
                    opacity: 1;
                }
            }
        }
    }

    .icon-holder {
        position: relative;

        .type-option-icon {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;

            transition: all 0.3s ease-in-out;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;
        }
    }

    .label {
        transition: all 0.3s ease-in-out;
    }
}
</style>
