import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'
import { rumAgent, logger } from '@moatmetrics/vue-logger'
import { usePatentViewerStore } from '@/stores'

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    const patentViewerStore = usePatentViewerStore()
    if (patentViewerStore) {
        patentViewerStore.resetState()
    }
    next()
})

router.afterEach((to) => {
    try {
        let viewName = to.name

        if (to.query?.tabName) {
            viewName = `${to.name} - ${to.query.tabName} Tab`
        }
        rumAgent.setView(viewName)
    } catch (error) {
        logger.error(error)
    }
})

export default router
